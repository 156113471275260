var viewMedia = {
    el: {mnp: '#rvw-mnp'},
    template: {
        mnp: '<div id="rvw-mnp"><div class="mnp-gallery"><div class="mnp-header"><button class="btn-close" aria-label="kapat"><svg class="icon icon-close"><use xlink:href="assets/images/sprite-map.svg#close"></use></svg></button></div><div class="mnp-content"></div></div>',
        swp: '<div class="swiper-container"><div class="swiper-wrapper">{{SLD}}</div><div class="swiper-controls swiper-theme-light"><div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-disabled="false"></div><div class="swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-disabled="true"></div></div></div></div>',
        sld: '<div class="swiper-slide">{{CNT}}</div>'
    },
    swiper: function(ths){
        var _t = this, html = '';

        $(ths + ' button[data-image]').each(function(){
            src = $(this).attr('data-image');
            html += _t.template['sld'].replace('{{CNT}}', '<img src="' + src + '" />');
        });

        $(_t.el['mnp'] + ' .mnp-content').html(_t.template['swp'].replace('{{SLD}}', html));
        new MinusSwiper({ ID: $(_t.el['mnp'] + ' .swiper-container')[0] });
    },
    thumb: function(ths){
        var _t = this;

        if( ! utils.detectEl($(_t.el['mnp'])[0]))
            $('body').append(_t.template['mnp']);

        $(ths + ' button[data-image]').each(function(i){
            $(this).off('click').on('click', function(){
                _t.swiper(ths);
                _t.open();
                $(_t.el['mnp'] + ' .swiper-container')[0].focused(i);

                // Bind Close Gallery
                _t.close('image');
            });
        });

        $(ths + ' button[data-video]').off('click').on('click', function(){
            src = $(this).attr('data-video');
            $(_t.el['mnp'] + ' .mnp-content').html('<video src="' + src + '" controls autoplay></video>');
            _t.open();

            // Bind Close Gallery
            _t.close('video');
        });
    },
    open: function(){
        var _t = this;

        $(_t.el['mnp']).addClass('activated');
        $('body').addClass('ajx-mnp-ready');
    },
    close: function(type){
        var _t = this;

        $(_t.el['mnp'] + ' .btn-close, #overlay').off('click').on('click', function(){
            $(_t.el['mnp']).removeClass('activated');
            $('body').removeClass('ajx-mnp-ready');

            if(type == 'image')
                $(_t.el['mnp'] + ' .swiper-container')[0].destroy();

            $(_t.el['mnp'] + ' .mnp-content').html('');
        });
    }
};