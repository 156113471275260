function promotion(wrp) {

    var promotion = {
        el: {
            tabPanel: '.tab-pane',
            activeTabPanel: '.promo-tabs .nav-link.active',
            item: '.prd-mini',
            btn: '.prd-mini .prd-mini-detail-left', // selected button
            promoSelections: '.promo-selections .promo-rows',
            promoRemoveBtn: '.btn-remove',
            promoItem: '.prd-row'
        },
        cls: {
            selected: 'selected',
            active: 'activeted', // tüm seçimler tamamlandıktan sonra eklenen class
        },

        template: '<div class="prd-row"> <div class="prd-row-inner"> <div class="prd-row-media"> <figure class="responsive"><img srcset="{{src}}"></figure> </div><div class="prd-row-block1"> <div class="prd-row-name">{{name}}</div><div class="prd-row-price">{{price}}</div></div><div class="prd-row-block2"> <button class="btn-remove" type="button"></button> </div></div></div>',

        checkSelectedProduct: function () {
            var _t = this,
                total = wrp.find('.promo-tab-contents > .tab-pane').length,
                selected = wrp.find('.promo-tab-contents .prd-mini.selected'),
                arr = [];

            selected
                .each(function () {

                    var ths = $(this),
                        src = ths.find('.responsive > img').attr('data-srcset') || '',
                        name = (ths.find('.prd-mini-name').html() || '').trim(),
                        price = (ths.find('.prd-mini-price').html() || '').trim();

                    arr.push(_t.template.replace(/{{src}}/g, src).replace(/{{name}}/g, name).replace(/{{price}}/g, price));

                });

            wrp.find(_t.el.promoSelections).html(arr.join(' '));

            ///
            wrp.find(_t.el.promoRemoveBtn)
                .off('click')
                .on('click', function () {
                    var ths = $(this),
                        item = ths.parents(_t.el.promoItem),
                        name = (item.find('.prd-row-name').text() || '').trim();

                    wrp
                        .find(_t.el.item)
                        .each(function () {
                            var ids = $(this),
                                mname = (ids.find('.prd-mini-name').text() || '').trim();

                            if (name == mname)
                                ids.removeClass(_t.cls['selected']);
                        });

                    item.remove();
                });

            ///    
            if (selected.length == total)
                wrp.addClass(_t.cls['active']);
            else
                wrp.removeClass(_t.cls['active']);

        },

        addEvent: function () {
            var _t = this;

            /* 
                select promotion buton
            */
            wrp.find(_t.el.btn)
                .on('click', function () {
                    var ths = $(this),
                        tabPanel = ths.parents(_t.el.tabPanel).eq(0), // butonların bağlı bulunduğu grup
                        item = tabPanel.find(_t.el.item), // her bir gruptaki itemlar
                        prts = ths.parents(_t.el.item); // itemların içindeki butonlar

                    if (prts.hasClass(_t.cls['selected']))
                        item.removeClass(_t.cls['selected']);
                    else {
                        item.removeClass(_t.cls['selected']);
                        prts.addClass(_t.cls['selected']);

                        var _next = wrp.find(_t.el.activeTabPanel).next();
                        if (_next.length > 0)
                            _next.trigger('click');
                        else
                            utils.pageScroll({ top: wrp.find(_t.el.promoSelections).offset().top - 150 });
                    }

                    _t.checkSelectedProduct();
                });
        },
        init: function () {
            var _t = this;
            _t.addEvent();
        }
    };

    promotion.init();

}
