var lazy;

function Main() {
    this.init();
}

Main.prototype = {
    constructor: Main,
    initPlugins: function (scope) {
        var _t = this;

        // LAZYLOAD
        lazy = new LazyLoad({
            elements_selector: '.lazy',
            callback_reveal: function (elm) {
                if (elm.tagName != 'IMG' && elm.hasAttribute('data-bg'))
                    elm.classList.add('bg-loaded');
            }
        });
        
    // JS Triggers
        // config.js
        SITE_CONFIG.general.preloader = '<div class="preloader-inline">' + document.querySelector('.preloader').innerHTML + '</div>';
        SITE_CONFIG.general.url = document.querySelector('link[rel=canonical]') && document.querySelector('link[rel=canonical]').href || window.location.href;
        // events.js
        events.init();
        // custom-menu.js
        customMenu.init();
        // multipleProductImage
        multipleProductImage.init();
        // compare.js
        compare.init();
        // customSearch.js
        customSearch.init();
        supportSearch.init();
        // loginWizard.js
        loginWizard.init();
        // promotion.js
        (function () {
            $('.promo-holder').each(function(){
                promotion($(this));
            });
        }());
        // targetElement.js
        targetElement.init();
        // Accordion.js
        accordion.init();
        // filter.js
        PLPfilter.init();
        loadAssistant.init();
        PLPassistant.init();
        PLPcouponCounter.init();
        // pdp.js
        pdpControls.init();
        // purchase.js
        purchaseControls.init();
        // countdownCustom.js
        dealCountdown.init();
        // cleaveCustom.js
        inputMask.init();
        // customMap.js
        // storesAvaiblableDelivery.init();
        // stores.init();
        // share.js
        customShare.init();

        // SWIPER
        var swiperElem = scope.querySelectorAll('[data-swiper]'),
            swiperTrigger = function () {
                utils.forEach(swiperElem, function (index, elm) {
                    var cls = 'swiper-activeted';
                    if ( ! $(elm).hasClass(cls)) {
                        elm.classList.add(cls);
                        new MinusSwiper({ ID: elm });
                    }
                });
            };

        if (swiperElem.length > 0 && typeof MinusSwiper !== 'undefined')
            swiperTrigger();
        
        // sliderVideo.js
        sliderVideo.init();
        
        // Animate on Scroll
        if(isMobile)
            scrollEffector.init('.se-item', SITE_CONFIG.animations.mobile);
        else
            scrollEffector.init('.se-item', SITE_CONFIG.animations.desktop);

        // Footer back to top
        $('#site-footer .btn-page-top').on('click', function(){
            utils.pageScroll({top: 0});
        });

        // Pikaday
        if(utils.detectEl($('[data-datepick]')[0])){
            var datepicker, dpConfig;

            $.cachedScript(extJSPath + 'pikaday.min.js').done(function(script, textStatus) {
                $('[data-datepick]').each(function(){
                    dpConfig = SITE_CONFIG.plugin.pikaday.sets[$(this).attr('data-datepick')];
                    dpConfig['field'] = this;

                    for(i in SITE_CONFIG.plugin.pikaday.defaults)
                        dpConfig[i] = SITE_CONFIG.plugin.pikaday.defaults[i];

                    datepicker = new Pikaday(dpConfig);
                });
            });
        }

        // Scrollable Tabs Selected
        var scrOl;
        $('.nav.scrollable-h').each(function(){
            if(utils.detectEl($('.active', this)[0])){
                scrOl = $('.active', this).position().left;
                $(this).scrollLeft(scrOl - 20);
            }
        });

        // Copy to Clipboard
        if(utils.detectEl($('[data-clipboard]')[0])){
            $('[data-clipboard]').on('click', function(){
                clipboardItem.init(this);
            });
        }

        // Event Detail Gallery
        if(utils.detectEl($('.swiper-gallery')[0]) && ! isMobile){
            var prn;
            $('.swiper-gallery .swiper-slide figure').on('click', function(){
                prn = $(this).parents('.gallery');
                bdy.addClass('gallery-opened');
                $('.swiper-container', prn).addClass('swiper-theme-light');
                $('.swiper-container', prn)[0].update();
                $('#overlay, .btn-gallery-close').off().on('click', function(){
                    bdy.removeClass('gallery-opened');
                    $('.swiper-container', prn).removeClass('swiper-theme-light');
                    $('.swiper-container', prn)[0].update();
                });
            }); 
        }

        // Top Stripe
        if(utils.detectEl($('#top-stripe')[0])){
            $('#top-stripe').on('close.bs.alert', function () {
                bdy.removeClass('top-stripe-opened');
            });
        }

        // Home Category Swiper
        if(utils.detectEl($('#category-swiper')[0])){
            if($('#category-swiper .swiper-slide').length < 5)
                $('#category-swiper').addClass('c-aligned');
        }

        // Order Cancel/Return
        if(utils.detectEl($('.ord-cancellation')[0])){
            var prn;
            $('.ocp-a > .radio input').on('change', function () {
                prn = $(this).parents('.ocp-row');
                $('.ocp-a.active', prn).removeClass('active');
                $(this).parents('.ocp-a').addClass('active');
            });
        }
    },
    init: function () {
        var _t = this;
        _t.initPlugins(document);
    }
};

new Main();

// dispatcher

function ON_READY() {
    // console.log('ON_READY bodyde belirlenen class');
    document.body.classList.remove('holding');
}
stage.addEventListener("CustomEvent", [{ type: 'EVENTS_ON_READY', handler: 'ON_READY' }]);

/* 
    populer products
*/
function ON_SWIPER_EVENT(o) {
    var ID = o['ID'],
        type = o['type'] || '';
    if (type == 'reachEnd') {
        setTimeout(function () {
            var wrp = ID.querySelector('.swiper-wrapper'),
                sld = wrp.querySelectorAll('.swiper-slide');
            utils.forEach(sld, function (i, ths) {
                ths.classList.remove('swiper-slide-active');
            });
            sld[sld.length - 1].classList.add('swiper-slide-active');
        }, 100);
    }

    /* 
        compare
    */
    var _ID = $(ID),
        k = _ID.attr('data-swiper') || '';
    if (k == 'compare' && type == 'slideChangeTransitionEnd') {
        var activePagination = _ID.find('.swiper-pagination-bullet-active').index() || 0;
        
        var pagination = $('#compare-all-features .swiper-container .swiper-pagination')
        .add( $('#compare-products .swiper-pagination')  )
        .add( $('.compare-top-panel .swiper-pagination')  );
        pagination
        .each(function(){
            var ths = $( this );
            ths.find('> span').eq( activePagination ).trigger('click');
        });
    }

    if (k == 'mainSlider' && type == 'slideChangeTransitionStart') {
        if($(ID).find('.swiper-slide-active').hasClass('swiper-slide-duplicate'))
            lazy.update();
    }
}
stage.addEventListener("CustomEvent", [{ type: 'SWIPER_ACTIVE_ELEMENT', handler: 'ON_SWIPER_EVENT' }]);







///////////////////////////// DISPATCHER YAPISI

/* 
    USAGE: 
    dispatcher({ type: 'LIST_LOADED', data: { ID: $( ID ) }  });
*/

var dispatcher = function (o) {
    o = o || {};
    var type = o['type'] || '',
        data = o['data'] || {};

    if (typeof stage !== 'undefined' && type != '')
        stage.dispatchEvent("CustomEvent", type, data);
};

/* 
    ex: 
    dispatcher({ type: 'LIST_LOADED', data: { ID: $( ID ) }  });


    data ile istediğin her turlu objeyi yollayabilirsin. Tab yapısında mesela ajx ile dolduktan sonra

    ajx(function(res){
        .
        .
        .
            dispatcher({ type: 'TAB_LOADED', data: { ID: $( ID ) }  });
    });

*/

function ON_TAB_LOADED( o ){
    /* 
        ajx tab pluginlerini burda tetikle
    */
    if(o['id'] == 'PDP_TAB_REVIEWS'){
        lazy.update();
        pdpControls.reviews.init();
    }
}
stage.addEventListener("CustomEvent", [{ type: 'TAB_LOADED', handler: 'ON_TAB_LOADED' }]);