var sliderVideo = {
    el: {
        main: '#main-slider',
        trg: '[data-video]',
        vid: '.msv-inline',
        close: '.msv-close'
    },
    template: '<div class="msv-inline"><video src="{{SRC}}" controls autoplay width="100%" id="{{ID}}"></video><button class="msv-close" type="button" aria-label="kapat"></button></div>',
    build: function(obj){
        var _t = this, ID = 'ms-video-' + obj['inx'];

        $(_t.el['main'])[0].autoplay('stop');
        $(_t.el['main']).addClass('msv-ready');
        obj['prn'].append(_t.template.replace('{{SRC}}', obj['src']).replace('{{ID}}', ID));

        // Event bind to end of the video
        document.querySelector('#' + ID).addEventListener('ended', function(){
            _t.destroy(obj);
        });

        // Event bind to close button
        obj['prn'].find(_t.el['vid']).find(_t.el['close']).on('click', function(){
            _t.destroy(obj);
        });
    },
    bind: function(){
        var _t = this, obj = {};

        $(_t.el['main'] + ' ' + _t.el['trg']).each(function(){
            obj['elm'] = $(this);
            obj['prn'] = obj['elm'].parents('.swiper-slide');
            obj['src'] = obj['elm'].attr('data-video');
            obj['inx'] = obj['prn'].attr('data-order');

            if(obj['src'] != undefined && obj['src'] != ''){
                obj['elm'].on('click', function(){
                    _t.build(obj);
                });
            }
        });
    },
    destroy: function(obj){
        var _t = this;

        $(_t.el['main'])[0].autoplay('start');
        obj['prn'].find(_t.el['vid']).remove();
        $(_t.el['main']).removeClass('msv-ready');
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['main'])[0]))
            _t.bind();
    }
};