var targetElement = {
    el: {
        btn: '[data-target-elem]'
    },
    cls: {
        defCls: 'activeted'
    },
    onClick: function (evt) {
        evt.preventDefault();
        var _t = targetElement,
            ths = $(evt.currentTarget),
            cls = ths.attr('data-cls') || _t.cls['defCls'],
            type = ths.attr('data-type') || 'add',
            target = $(ths.attr('data-target-elem') || '');

        if (target.length > 0)
            switch (type) {
                case 'add':
                    target.addClass(cls)
                    break;

                case 'remove':
                    target.removeClass(cls)
                    break;

                case 'toggle':
                    if (target.hasClass(cls))
                        target.removeClass(cls);
                    else
                        target.addClass(cls);
                    break;

                default:
                    break;
            }

    },
    addEvent: function () {
        var _t = this,
            btn = $(_t.el.btn);

        btn
            .off('click', _t.onClick)
            .on('click', _t.onClick)
    },
    init: function () {
        var _t = this;
            _t.addEvent();
    }
};