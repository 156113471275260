/* 
    <button class="button-clear btn-menu menu-button" data-target="#main-menu" data-bdy="menu-ready"></button>
    <div class="menu-target" id="main-menu"></div>
*/

var customMenu = {
    el: {
        btn: '.js-ov-trg', // menu açmak için ortak class
        target: '.js-ov-target', // menu içerikleri için ortak class
        closeBtn: '#overlay, .btn-close, .btn-back', // bu kısımda overlay butonlar tanımlanır
        subMenuBtn: '.menu-category > li', // sub menu kategori
        subMenuAccBtn: '.menu-category > li > a', // sub menu kategori
        lv2Btn: '.menu-category .lv2-li',
        lv2AccBtn: '.menu-category .lv2-li > a' // lv2 kategori
    },
    cls: {
        activated: 'activated',
        selected: 'selected',
        nosub: 'no-sub'
    },
    addEvent: function () {
        var _t = this,
            btn = document.querySelectorAll(_t.el.btn),
            closeBtn = document.querySelectorAll(_t.el.closeBtn),
            subMenuBtn = document.querySelectorAll(_t.el.subMenuBtn);

        if (utils.detectEl(btn))
            utils.forEach(btn, function (ind, elm) {
                elm.addEventListener('click', function (evt) {
                    evt.preventDefault();
                    var ths = this;

                    if ($(ths).hasClass(_t.cls['activated'])) {
                        _t.destroy();
                    } else {
                        _t.destroy();
                        utils.setClass({ target: [ths], cls: _t.cls['activated'], type: 'add' });

                        var _target = ths.getAttribute('data-target') || '', 
                            bdyCls = ths.getAttribute('data-bdy') || '',
                            dispType = _target;

                        if (_target != '') {
                            _target = document.querySelectorAll(_target);
                            if (utils.detectEl(_target))
                                utils.setClass({ target: _target, cls: _t.cls['activated'], type: 'add' });

                            if (bdyCls != ''){
                                document.body.classList.add(bdyCls);
    
                                if(bdyCls == 'menu-mnp-ready'){
                                    if($(ths).parents('.prd-more').length > 0){
                                        customShare.getObj(ths, 'plp');
                                    }else if($(ths).parents('.pdp-more').length > 0 || $(ths).parents('.pdp-breadcrump').length > 0){
                                        customShare.getObj(ths, 'pdp');
                                    }else if($(ths).parents('.evt-details').length > 0){
                                        customShare.getObj(ths, 'mll');
                                    }else if($(ths).parents('.page-ml-detail').length > 0){
                                        customShare.getObj(ths, 'mld');
                                    }
                                    customShare.prepareLinks();
                                }
                            }

                            // Dispatch when popup opened
                            stage.dispatchEvent("CustomEvent", dispType + '.opened', {elm: _target[0], trigger: ths});

                            // Event Listener for ajax popup loaded
                            // stage.addEventListener("CustomEvent", [{ type: dispType, handler: 'ON_AJAX_POPUP_LOADED' }]);
                        }                        
                    }
                });
            });

        if (utils.detectEl(closeBtn))
            utils.forEach(closeBtn, function (ind, elm) {
                elm.addEventListener('click', function (evt) {
                    evt.preventDefault();
                    _t.destroy();
                });
            });

        if (utils.detectEl(subMenuBtn)) {

            if (utils.responsiveControl()) {
                /* 
                    mobile ozel
                */
                var prts, lv2Btn = document.querySelectorAll(_t.el.lv2Btn);
                // utils.forEach(lv2AccBtn, function (ind, elm) {
                //     prts = $(elm).parents('li');
                //     // if( ! prts.hasClass(_t.cls['nosub'])){
                //         elm.addEventListener('click', function (evt) {
                //             evt.preventDefault();
                //             prts = $(this).parents('li');

                //             if(prts.hasClass(_t.cls['selected'])){
                //                 utils.setClass({ target: lv2Btn, cls: _t.cls['selected'], type: 'remove' });
                //             }else {
                //                 utils.setClass({ target: lv2Btn, cls: _t.cls['selected'], type: 'remove' });
                //                 prts.addClass(_t.cls['selected']);
                //             }
                //         });
                //     // }
                // });
                $(_t.el.lv2AccBtn).on('click', function(e){
                    prts = $(this).parents('.lv2-li');
                    if( ! prts.hasClass(_t.cls['nosub'])){
                        e.preventDefault();
                        if(prts.hasClass(_t.cls['selected'])){
                            console.log('lv1 fired!1');
                            utils.setClass({ target: lv2Btn, cls: _t.cls['selected'], type: 'remove' });
                        }else {
                            console.log('lv1 fired!2');
                            utils.setClass({ target: lv2Btn, cls: _t.cls['selected'], type: 'remove' });
                            prts.addClass(_t.cls['selected']);
                        }
                    }
                });
                $(_t.el.subMenuAccBtn).on('click', function(e){
                    prts = $(this).parents('li');
                    if( ! prts.hasClass(_t.cls['nosub'])){
                        e.preventDefault();
                        if( ! prts.hasClass(_t.cls['selected'])){
                            prts.addClass(_t.cls['selected']);
                            $('.menu-category').addClass('lv1-active');
                        }else{
                            prts.removeClass(_t.cls['selected']);
                            $('.menu-category').removeClass('lv1-active');
                        }
                    }
                });
            }
            else

                utils.forEach(subMenuBtn, function (ind, elm) {
                    if(ind == 0){
                        elm.classList.add(_t.cls['selected']);
                    }
                    
                    elm.addEventListener('mouseenter', function (evt) {
                        var ths = this;
                        if( ! $(ths).hasClass(_t.cls['nosub'])){
                            evt.preventDefault();
                            utils.setClass({ target: subMenuBtn, cls: _t.cls['selected'], type: 'remove' });
                            ths.classList.add(_t.cls['selected']);
                        }
                    });
                });

        }


    },
    destroy: function () {
        var _t = this,
            elm = document.querySelectorAll(_t.el.btn), dispObj;

        /* 
            tum butonlardan activated classını kaldırır
        */
        if (utils.detectEl(elm))
            utils.setClass({ target: elm, cls: _t.cls['activated'], type: 'remove' });

        /* 
            tum butonlardan uzerindeki data-bdy kullanarak bodydeki classları siler
        */
        utils.forEach(elm, function (index, btn) {
            var bdyCls = btn.getAttribute('data-bdy') || '';
            if (bdyCls != '')
                document.body.classList.remove(bdyCls);
        });

        /* 
            tum targetlardan activated classını kaldırır
        */
        elm = document.querySelectorAll(_t.el.target);
        if (utils.detectEl(elm)){
            // Dispatch when popup closed
            if(dispObj = document.querySelector(_t.el.target + '.' + _t.cls['activated'])){
                utils.setClass({ target: elm, cls: _t.cls['activated'], type: 'remove' });
                stage.dispatchEvent("CustomEvent", '#' + dispObj.getAttribute('id') + '.closed', {elm: dispObj, trigger: null});
            }
        }

        
    },
    init: function () {
        var _t = this;
        _t.addEvent();
    }
};