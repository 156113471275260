var multipleProductImage = {
    el: {
        con: '.swiper-multiple-product-image',
        bullet: '.swiper-pagination-inner > span'
    },
    cls: {
        active: 'activeted'
    },
    add: function (ID) {
        var _t = this,
            temp = 0,
            swiper = ID.find('.swiper-container'),
            length = ID.find('.swiper-slide').length;

        ID
            .off('mousemove mouseleave')
            .on('mousemove', function (evt) {
                var ths = $(this),
                    width = ths.width(),
                    bullet = ID.find( _t.el.bullet ),
                    offsetX = Math.abs(Math.round(evt.pageX - ths.offset().left)),
                    n = Math.floor(offsetX / Math.round(width / length));
                if (n <= 0) n = 0;
                if (n >= length) n = length - 1;
                if (swiper.length > 0 && temp != n)
                    bullet.eq( n ).trigger('click');
                    
                temp = n;
            })
            .on('mouseleave', function (evt) {
                var ths = $(this),
                    swiper = ths.find('.swiper-container'),
                    bullet = ID.find( _t.el.bullet );

                if (swiper.length > 0)
                    bullet.eq( 0 ).trigger('click');

                temp = 0;
            });
    },
    init: function () {
        var _t = this,
            con = $(_t.el.con);
        if (!isMobile && con.length > 0) {
            con
                .each(function () {
                    var ths = $(this);
                    if (!ths.hasClass(_t.cls['active'])) {
                        ths.addClass(_t.cls['active']);
                        _t.add(ths);
                    }
                });
        }
    },
};