var PLPfilter = {
    el: '#modal-filters',
    activated: false,
    typeSlider: {
        el: '.range-slider',
        build: function(){
            var _t = this, format = {thousand: '.'}, rs, min, max, pips, pipLen;
    
            $(_t.el).each(function(){
                rs = $(this),
                format[rs.attr('data-curr-pos')] = rs.attr('data-curr-txt'),
                min = parseFloat(rs.attr('data-min')),
                max = parseFloat(rs.attr('data-max')),
                pips = rs.attr('data-pips');

                if(pips != undefined){
                    pips = pips.split('|'),
                    pipLen = pips.length;

                    for(i in pips)
                        pips[i] = parseFloat(pips[i]);

                    noUiSlider.create(this, {
                        start: [pips[0], pips[pipLen-1]],
                        connect: true,
                        tooltips: [wNumb(format), wNumb(format)],
                        range: {
                            min: pips[0],
                            max: pips[pipLen-1]
                        },
                        pips: {
                            mode: 'values',
                            values: pips,
                            density: 100
                        }
                    });
                }else{
                    noUiSlider.create(this, {
                        start: [min, max],
                        connect: true,
                        tooltips: [wNumb(format), wNumb(format)],
                        step: parseFloat(rs.attr('data-step')),
                        range: {
                            min: min,
                            max: max
                        }
                    });
                }
            });
    
            
        },
        init: function(){
            var _t = this;
    
            if(utils.detectEl($(_t.el)[0])){
                $.cachedScript(extJSPath + 'nouislider.min.js').done(function(script, textStatus) {
                    _t.build();
                });
            }
        }
    },
    typeButton: {
        el: {main: '.fg-type-button', body: '.fg-body'},
        init: function(){
            var _t = this;

            $(_t.el['main'] + ' ' + _t.el['body'] + ' button').on('click', function(){
                $(this).toggleClass('selected');
            });
        }
    },
    fgMore: {
        el: {main: '.fg-more', parent: '.filter-group'},
        init: function(){
            var _t = this;

            $(_t.el['main']).on('click', function(){
                $(this).parents(_t.el['parent']).removeClass('fg-less');
            });
        }
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el)[0])){
            $(_t.el).on('show.bs.modal', function (e) {
                if( ! _t.activated){
                    _t.typeSlider.init();
                    _t.typeButton.init();
                    _t.fgMore.init();
                    _t.activated = true;
                }
            });
        }
    }
};

// Assistant
var loadAssistant = {
    el: {main: '#modal-assistant'},
    loadJS: function(){
        var _t = this, path = $(_t.el['main'] + ' [data-poltio-js]').attr('data-poltio-js');

        $.cachedScript(path).done(function(script, textStatus) {
            console.log('Assistant JS loaded!');
        });
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['main'])[0]))
            _t.loadJS();
    }
};

// PLP Assistant
var PLPassistant = {
    el: {main: '#assistant', banner: '#category-banners', page: '.page-product-list'},
    check: function(){
        var _t = this, ord, elm = $(_t.el['page'] + ' ' + _t.el['main']);

            if(isMobile){
                ord = parseFloat(elm.attr('data-mobile-order')) - 1;
                // elm.addClass('compact');
                // elm.on('click', function(){
                //     $(this).removeClass('compact');
                // });
            }else{
                ord = parseFloat(elm.attr('data-desktop-order')) - 1;
            }

            elm.insertBefore('.products .prd:eq(' + ord + ')').addClass('assistant-in-list');
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['page'] + ' ' + _t.el['main'])[0]) && utils.detectEl($(_t.el['banner'])[0]))
            _t.check();
    }
};

// PLP Coupon Counter
var PLPcouponCounter = {
    el: {main: '#coupon-counter', button: '.ccm-revealer .btn'},
    reveal: function(){
        var _t = this, time = $(_t.el['main'] + ' .time').attr('data-bind');

        if(time){
            $(_t.el['main']).addClass('show-coupon');
            $.cachedScript(extJSPath + 'jquery.countdown.min.js').done(function(script, textStatus) {
                _t.setCounter(new Date(time));
            });
        }else{
            $(_t.el['button']).on('click', function(){
                $.cachedScript(extJSPath + 'jquery.countdown.min.js').done(function(script, textStatus) {
                    _t.setCounter(_t.calcTime());
                    $(_t.el['main']).addClass('show-coupon');
                });
                
            });
        }
    },
    calcTime: function(){
        var _t = this, timer = $(_t.el['main'] + ' .time'), exp, time, ext;

        exp = parseFloat(timer.attr('data-time'));
        time = new Date();
        ext = exp % 60;
        exp = (exp - ext) / 60;
        time.setHours(time.getHours() + exp);
        time.setMinutes(time.getMinutes() + ext);
        timer.attr('data-bind', time);

        return time;
    },
    setCounter: function(time){
        var _t = this;

        $(_t.el['main'] + ' .time').countdown({
            until: time, 
            layout: SITE_CONFIG.plugin.countdown.hour,
            timeSeparator: '<span class="sep">:</span>',
            onExpiry: function(){
                console.log('TIME IS OVER!');
            }
        });
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['main'])[0]))
            _t.reveal();
    }
};