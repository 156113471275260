// Credit Card
function CreditCard(o) {
    o = o || {};
    var form = $(o['form'] || ''), // inputları kapsayan <form>
        container = $(o['container'] || ''); // kredi kartı gorselinin geldiği container
    if (container.length > 0 && form.length > 0) {
        new Card({
            form: form.get(0),
            container: o['container'] || '',
            formSelectors: {
                numberInput: o['numberInput'] || '',
                expiryInput: o['expiryInput'] || '',
                cvcInput: o['cvcInput'] || '',
                nameInput: o['nameInput'] || ''
            },
        });

        $( o['numberInput'] || '' )
        .add( $( o['expiryInput'] || '' ) )
        .add( $( o['cvcInput'] || '' ) )
        .add( $( o['nameInput'] || '' ) )
        .on('focus', function(){
            $( this ).parents( o['parents'] || '' ).addClass('credit-card-active');
        });
        // .on('blur', function(){
        //     $( this ).parents( o['parents'] || '' ).removeClass('credit-card-active');
        // });
    }
}