var inputMask = {
    el: 'input[data-mask]',
    configs: SITE_CONFIG.plugin.cleave,
    obj: {},
    getOptions: function(ths){
        var _t = this, set = $(ths).attr('data-mask'), format = {}, config;
        
        if(set == 'phoneCustom'){
            format['raw'] = $(ths).attr('data-format');
            format['rawLength'] = format['raw'].length;
            format['prefix'] = format['raw'].replace(/\*/g, '');
            format['prefixLength'] = format['prefix'].length;
            format['restLength'] = format['rawLength'] - format['prefixLength'];
            config = {
                delimiter: '',
                blocks: [format['prefixLength'], format['restLength']],
                prefix: format['prefix'],
                numericOnly: true,
                noImmediatePrefix: true,
                delimiterLazyShow: true
            };
        }else{
            config = _t.configs[set];
        }

        return config;
    },
    init: function(){
        var _t = this, id, val, prf, set;

        if(utils.detectEl($(_t.el)[0])){
            $.cachedScript(extJSPath + 'cleave.min.js').done(function(script, textStatus) {
                $(_t.el).each(function(){
                    id = $(this).attr('id');
                    val = $(this).val().trim();
        
                    _t.obj[id] = new Cleave(this, _t.getOptions(this));
                    
                    if(val != '')
                        _t.obj[id].setRawValue(val);
                });
        
                $('input[data-mask*="phone"]').off('blur').on('blur', function(){
                    set = $(this).attr('data-mask');

                    if(set == 'phoneCustom')
                        prf = $(this).attr('data-format').replace(/\*/g, '');
                    else
                        prf = _t.configs[$(this).attr('data-mask')].prefix;

                    id = $(this).attr('id');
                    val = inputMask.obj[id].getRawValue();
            
                    if(val == prf || val.length < prf.length)
                        $(this).val('');
                });
            });
        }
    }
};