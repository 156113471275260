/* 
    <input type="email" required="true" name="email" data-attr="email">
    progress step yapısında zorunlu alanlara required gelmeli
    ayrıca özel durumlar için mesela mail kontrol attribute eklenmeli "email"

*/

var bdy = $('body'),
    loginWizard = {
        el: {
            inputPass: '.password [type="password"]',
            showPassButton: '.password .wrp i',
            indicator: '.indicator',
            signupBtn: '.signup-btn .btn',
            guestBtn: '.guest-btn .btn',
            signupProgress: '.progress.signup',
            backButton: '.signup .prg-back button',
            guestBackBtn: '.guest .prg-back button',
            prgSummary: '.prg-summary',
            prtsStep: '.prg-step',
            stepBtn: '.progress .next > button',
            formRow: '.form-row',
            completeButton: '.btn-signup-complete',
            chkConfirm: '.form-row.confirm'
        },
        cls: {
            showPass: 'show-pass',
            signupReady: 'signup-ready',
            signupComplete: 'signup-complete',
            guestReady: 'guest-ready',
            formErr: 'form-error'
        },
        passCheck: {length: false, alpha: false, numeric: false},
        checkPasswordStrength: function (password) {
            var _t = this,
                indicator = $(_t.el.indicator),
                passed = 0;

            //Regular Expressions
            var regex = new Array();
            regex.push("[A-Z]"); //For Uppercase Alphabet
            regex.push("[a-z]"); //For Lowercase Alphabet
            regex.push("[0-9]"); //For Numeric Digits
            regex.push("[$@$!%*#?&]"); //For Special Characters

            for (var i = 0; i < regex.length; i++) {
                if ((new RegExp(regex[i])).test(password)) {
                    passed++;
                }
            }

            //Validation for Length of Password
            if (passed > 2 && password.length >= SITE_CONSTANT.minPasswordLength) {
                passed++;
            }

            if (passed >= 5)
                passed = 4;

            indicator.attr('data-level', passed);
        },

        validation: function (ID) {
            var _t = this,
                e = $('[required]', ID),
                b = true;

            ID.find(_t.el.formRow).removeClass(_t.cls['formErr']);

            e
                .each(function () {
                    var ths = $(this),
                        row = ths.parents(_t.el.formRow),
                        val = (ths.val() || '').trim(),
                        lngth = val.length,
                        attr = ths.attr('data-attr') || '';

                    if (ths.is('[type="checkbox"]') && !ths.is(':checked')) {
                        // checkbox seçili olmama durumu
                        row.addClass(_t.cls['formErr']);
                        b = false;
                    } else {

                        if (lngth == 0) {
                            // input boşsa
                            row.addClass(_t.cls['formErr']);
                            b = false;
                        }

                        if (lngth > 0 && attr != '') {
                            // input dolu ama ozel kontroller yapılıyor
                            switch (attr) {
                                case 'email':
                                    var regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
                                    if (!regex.test(val)) {
                                        row.addClass(_t.cls['formErr']);
                                        b = false;
                                    }
                                break;

                                case 'phone':
                                    var regex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/g;
                                    val = val
                                      .replace(/\./g, "")
                                      .replace(/\)/g, "")
                                      .replace(/\(/g, "")
                                      .replace(/\s+/g, "");

                                    if( !regex.test(val) ){
                                        row.addClass(_t.cls['formErr']);
                                        b = false;
                                    }
                                break;

                                case 'password':
                                    var regex = /^.{6,}$/;
                                    if( !regex.test(val) ){
                                        row.addClass(_t.cls['formErr']);
                                        b = false;
                                    }
                                break;

                                default:
                                    break;
                            }
                        }
                    }
                });

            return b;
        },

        addEvent: function () {
            var _t = this;

            /* 
                show hide password
            */
            $(_t.el.showPassButton)
                .on('click', function (evt) {
                    evt.preventDefault();
                    var ths = $(this),
                        input = ths.siblings('input');

                    if (ths.hasClass(_t.cls['showPass'])) {
                        ths.removeClass(_t.cls['showPass']);
                        input.prop('type', 'password');
                    } else {
                        ths.addClass(_t.cls['showPass']);
                        input.prop('type', 'text');
                    }
                });

            /* 
                password strength
            */
            $(_t.el.inputPass)
                .on('keyup paste', function () {
                    var ths = $(this),
                        val = (ths.val() || '').trim();
                    _t.checkPasswordStrength(val);
                });

            /* 
                signup Button
            */
            $(_t.el.signupBtn)
                .on('click', function () {
                    if(isMobile)
                        utils.pageScroll({top: 0});

                    bdy.addClass(_t.cls['signupReady']);
                });

            /* 
                Guest Button
            */
            $(_t.el.guestBtn)
                .on('click', function () {
                    if(isMobile)
                        utils.pageScroll({top: 0});

                    bdy.addClass(_t.cls['guestReady']);
                });

            /* 
                Guest Back Button
            */
           $(_t.el.guestBackBtn)
           .on('click', function () {
               bdy.removeClass(_t.cls['guestReady']);
           });

            /* 
                step
            */
            var signupProgress = $(_t.el.signupProgress),
                prgSummary = $(_t.el.prgSummary);

            $(_t.el.stepBtn)
                .on('click', function () {
                    var ths = $(this),
                        prts = ths.parents(_t.el.prtsStep);

                    if(isMobile)
                        utils.pageScroll({top: 0});

                    if (_t.validation(prts)) {
                        var total = parseFloat(signupProgress.attr('data-total') || '3'),
                            step = parseFloat(signupProgress.attr('data-step') || '1') + 1;

                        if (step > total)
                            step = total;

                        signupProgress.attr('data-step', step);
                        prgSummary.attr('data-step', step);
                    }
                });

            $(_t.el.backButton)
                .on('click', function () {
                    var step = parseFloat(signupProgress.attr('data-step') || '1');

                    if (step == 1)
                        bdy.removeClass(_t.cls['signupReady']);
                    else {
                        step--;
                        if (step <= 1)
                            step = 1;
                        signupProgress.attr('data-step', step);
                        prgSummary.attr('data-step', step);
                    }
                });
            
            // Complete
            $(_t.el.completeButton)
                .on('click', function () {
                    bdy.addClass(_t.cls['signupComplete']);
                });

        },
        init: function () {
            var _t = this;
            _t.addEvent();
        }
    };