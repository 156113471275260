var compare = {
    el: {
        main: '.compare-info',
        btn: '.prd-compare [type="checkbox"]',
        badge: '.compare-info .qty-badge',
        cancel: '.compare-info .btn-cancel',
        tabBtn: '.nav-tabs > button',
        accordion: '.accordion > li',
        openAll: '.btn-open-acc-items',
        page: '.page-compare'
    },
    cls: {
        selected: 'selected',
        activated: 'compare-activated bottom-sticky-bar',
        active: 'active',
        different: 'different',
        same: 'same',
        visibilty: 'visibilty'
    },

    
    onCancelClick: function () {
        var _t = compare,
            bdy = $('body'),
            btn = $(_t.el.btn);

        bdy.removeClass(_t.cls['activated']);

        btn
            .each(function () {
                var ths = $(this);
                ths.prop('checked', false);
                ths.parents('.prd').removeClass(_t.cls['selected']);
            });
    },
    onTabClick: function () {
        var _t = compare,
            ths = $(this),
            accordion = $(_t.el.accordion);

        ths.addClass(_t.cls.active).siblings().removeClass(_t.cls.active);

        accordion
            .removeClass(_t.cls.visibilty);

        accordion
            .find('.' + _t.cls.different)
            .removeClass(_t.cls.different);

        accordion
            .find('.' + _t.cls.same)
            .removeClass(_t.cls.same);

        switch (true) {
            case ths.hasClass('btn-all'):
                accordion.addClass(_t.cls['active']);
                break;

            // case ths.hasClass('btn-populars'):
            //     accordion.removeClass(_t.cls['active']);
            //     break;

            case ths.hasClass('btn-differents'):

                accordion
                    .each(function () {
                        var ths = $(this),
                            sld = ths.find('.swiper-slide');

                        // sld.each(function(){
                        //     var swiper = $(this);

                        //     swiper.find('.item').each(function (ind) {
                        //         var item = $(this),
                        //             value = (item.text() || '').trim(),
                        //             target = sld.not(':eq(0)'),
                        //             b = false;
                        //     });
                        // });

                        sld
                            .eq(0)
                            .each(function () {
                                var swiper = $(this);

                                swiper
                                    .find('.item')
                                    .each(function (ind) {
                                        var item = $(this),
                                            value = (item.text() || '').trim(),
                                            target = sld.not(':eq(0)');
                                            b = false;

                                        target
                                            .each(function () {
                                                var targetItem = $(this).find('.item').eq(ind),
                                                    targetValue = (targetItem.text() || '').trim();

                                                    if (targetValue != value) {
                                                        b = false;
                                                        return false;
                                                    } else
                                                        b = true;
                                            });

                                        if(b){
                                            sld.each(function () {
                                                $(this).find('.item').eq(ind).addClass(_t.cls.same);
                                            });
                                        }
                                    });


                                // eğer tüm içerik aynıysa visibilty classı alsın
                                if (swiper.find('.item').length == swiper.find('.item.' + _t.cls.same).length) {
                                    sld.parents('li').eq(0).addClass(_t.cls['visibilty']);
                                }
                            });
                    });


                break;

            default:
                break;
        }
    },
    onOpenAll: function(){
        var _t = compare,
            ths = $(this),
            accordion = $(_t.el.accordion);

        accordion.addClass(_t.cls['active']);
    },
    addEvent: function () {
        var _t = this;
        
        $(_t.el.cancel)
            .off('click', _t.onCancelClick)
            .on('click', _t.onCancelClick);

        $(_t.el.tabBtn)
            .off('click', _t.onTabClick)
            .on('click', _t.onTabClick);

        $(_t.el.openAll)
            .off('click', _t.onOpenAll)
            .on('click', _t.onOpenAll);

    },
    check: function () {
        var _t = this,
            bdy = $('body'),
            btn = $(_t.el.btn + ':checked'), prn;

        $(_t.el['badge']).text(btn.length);

        if (btn.length == 0){
            bdy.removeClass(_t.cls['activated']);
        }else{
            bdy.addClass(_t.cls['activated']);
            $(_t.el.btn + ':checked').each(function(){
                prn = $(this).parents('.prd');

                if( ! prn.hasClass(_t.cls['selected']))
                    prn.addClass(_t.cls['selected']);
            });
        }
        
        
    },
    init: function () {
        var _t = this;

        if(utils.detectEl($(_t.el.main)[0]) || utils.detectEl($(_t.el.page)[0])){
            $(function(){
                _t.check();
            });
            _t.addEvent();
        }
    }
};
