var accordion = {
    el: {
        main: '.accordion',
        btn: '.acc-item > button'
    },
    cls: {
        active: 'active'
    },
    addEvent: function () {
        var _t = this;
        $(_t.el.main)
            .find(_t.el.btn)
            .on('click', function () {
                var ths = $(this),
                    type = ths.parents(_t.el.main).attr('data-type') || 'connected',
                    prts = ths.parents('li').eq(0);

                if (type == 'connected') {
                    if (prts.hasClass(_t.cls['active']))
                        prts.add(prts.siblings()).removeClass(_t.cls['active']);
                    else
                        prts.addClass(_t.cls['active']).siblings().removeClass(_t.cls['active']);
                } else {
                    if (prts.hasClass(_t.cls['active']))
                        prts.removeClass(_t.cls['active']);
                    else
                        prts.addClass(_t.cls['active']);

                }
            });
    },
    init: function () {
        var _t = this;
        _t.addEvent();
    }
};