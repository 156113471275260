var translation = {
    infoWindowMap: 'İçerik Yükleniyor'
};

/* js-marker-clusterer */
(function () {
    var d = null; function e(a) { return function (b) { this[a] = b } } function h(a) { return function () { return this[a] } } var j;
    function k(a, b, c) {
        this.extend(k, google.maps.OverlayView); this.c = a; this.a = []; this.f = []; this.ca = [53, 56, 66, 78, 90]; this.j = []; this.A = !1; c = c || {}; this.g = c.gridSize || 60; this.l = c.minimumClusterSize || 2; this.J = c.maxZoom || d; this.j = c.styles || []; this.X = c.imagePath || this.Q; this.W = c.imageExtension || this.P; this.O = !0; if (c.zoomOnClick != void 0) this.O = c.zoomOnClick; this.r = !1; if (c.averageCenter != void 0) this.r = c.averageCenter; l(this); this.setMap(a); this.K = this.c.getZoom(); var f = this; google.maps.event.addListener(this.c,
            "zoom_changed", function () { var a = f.c.getZoom(); if (f.K != a) f.K = a, f.m() }); google.maps.event.addListener(this.c, "idle", function () { f.i() }); b && b.length && this.C(b, !1)
    } j = k.prototype; j.Q = "http://google-maps-utility-library-v3.googlecode.com/svn/trunk/markerclusterer/images/m"; j.P = "png"; j.extend = function (a, b) { return function (a) { for (var b in a.prototype) this.prototype[b] = a.prototype[b]; return this }.apply(a, [b]) }; j.onAdd = function () { if (!this.A) this.A = !0, n(this) }; j.draw = function () { };
    function l(a) { if (!a.j.length) for (var b = 0, c; c = a.ca[b]; b++)a.j.push({ url: a.X + (b + 1) + "." + a.W, height: c, width: c }) } j.S = function () { for (var a = this.o(), b = new google.maps.LatLngBounds, c = 0, f; f = a[c]; c++)b.extend(f.getPosition()); this.c.fitBounds(b) }; j.z = h("j"); j.o = h("a"); j.V = function () { return this.a.length }; j.ba = e("J"); j.I = h("J"); j.G = function (a, b) { for (var c = 0, f = a.length, g = f; g !== 0;)g = parseInt(g / 10, 10), c++; c = Math.min(c, b); return { text: f, index: c } }; j.$ = e("G"); j.H = h("G");
    j.C = function (a, b) { for (var c = 0, f; f = a[c]; c++)q(this, f); b || this.i() }; function q(a, b) { b.s = !1; b.draggable && google.maps.event.addListener(b, "dragend", function () { b.s = !1; a.L() }); a.a.push(b) } j.q = function (a, b) { q(this, a); b || this.i() }; function r(a, b) { var c = -1; if (a.a.indexOf) c = a.a.indexOf(b); else for (var f = 0, g; g = a.a[f]; f++)if (g == b) { c = f; break } if (c == -1) return !1; b.setMap(d); a.a.splice(c, 1); return !0 } j.Y = function (a, b) { var c = r(this, a); return !b && c ? (this.m(), this.i(), !0) : !1 };
    j.Z = function (a, b) { for (var c = !1, f = 0, g; g = a[f]; f++)g = r(this, g), c = c || g; if (!b && c) return this.m(), this.i(), !0 }; j.U = function () { return this.f.length }; j.getMap = h("c"); j.setMap = e("c"); j.w = h("g"); j.aa = e("g");
    j.v = function (a) { var b = this.getProjection(), c = new google.maps.LatLng(a.getNorthEast().lat(), a.getNorthEast().lng()), f = new google.maps.LatLng(a.getSouthWest().lat(), a.getSouthWest().lng()), c = b.fromLatLngToDivPixel(c); c.x += this.g; c.y -= this.g; f = b.fromLatLngToDivPixel(f); f.x -= this.g; f.y += this.g; c = b.fromDivPixelToLatLng(c); b = b.fromDivPixelToLatLng(f); a.extend(c); a.extend(b); return a }; j.R = function () { this.m(!0); this.a = [] };
    j.m = function (a) { for (var b = 0, c; c = this.f[b]; b++)c.remove(); for (b = 0; c = this.a[b]; b++)c.s = !1, a && c.setMap(d); this.f = [] }; j.L = function () { var a = this.f.slice(); this.f.length = 0; this.m(); this.i(); window.setTimeout(function () { for (var b = 0, c; c = a[b]; b++)c.remove() }, 0) }; j.i = function () { n(this) };
    function n(a) {
        if (a.A) for (var b = a.v(new google.maps.LatLngBounds(a.c.getBounds().getSouthWest(), a.c.getBounds().getNorthEast())), c = 0, f; f = a.a[c]; c++)if (!f.s && b.contains(f.getPosition())) {
            for (var g = a, u = 4E4, o = d, v = 0, m = void 0; m = g.f[v]; v++) {
                var i = m.getCenter(); if (i) {
                    var p = f.getPosition(); if (!i || !p) i = 0; else var w = (p.lat() - i.lat()) * Math.PI / 180, x = (p.lng() - i.lng()) * Math.PI / 180, i = Math.sin(w / 2) * Math.sin(w / 2) + Math.cos(i.lat() * Math.PI / 180) * Math.cos(p.lat() * Math.PI / 180) * Math.sin(x / 2) * Math.sin(x / 2), i = 6371 * 2 * Math.atan2(Math.sqrt(i),
                        Math.sqrt(1 - i)); i < u && (u = i, o = m)
                }
            } o && o.F.contains(f.getPosition()) ? o.q(f) : (m = new s(g), m.q(f), g.f.push(m))
        }
    } function s(a) { this.k = a; this.c = a.getMap(); this.g = a.w(); this.l = a.l; this.r = a.r; this.d = d; this.a = []; this.F = d; this.n = new t(this, a.z(), a.w()) } j = s.prototype;
    j.q = function (a) {
        var b; a: if (this.a.indexOf) b = this.a.indexOf(a) != -1; else { b = 0; for (var c; c = this.a[b]; b++)if (c == a) { b = !0; break a } b = !1 } if (b) return !1; if (this.d) { if (this.r) c = this.a.length + 1, b = (this.d.lat() * (c - 1) + a.getPosition().lat()) / c, c = (this.d.lng() * (c - 1) + a.getPosition().lng()) / c, this.d = new google.maps.LatLng(b, c), y(this) } else this.d = a.getPosition(), y(this); a.s = !0; this.a.push(a); b = this.a.length; b < this.l && a.getMap() != this.c && a.setMap(this.c); if (b == this.l) for (c = 0; c < b; c++)this.a[c].setMap(d); b >= this.l && a.setMap(d);
        a = this.c.getZoom(); if ((b = this.k.I()) && a > b) for (a = 0; b = this.a[a]; a++)b.setMap(this.c); else if (this.a.length < this.l) z(this.n); else { b = this.k.H()(this.a, this.k.z().length); this.n.setCenter(this.d); a = this.n; a.B = b; a.ga = b.text; a.ea = b.index; if (a.b) a.b.innerHTML = b.text; b = Math.max(0, a.B.index - 1); b = Math.min(a.j.length - 1, b); b = a.j[b]; a.da = b.url; a.h = b.height; a.p = b.width; a.M = b.textColor; a.e = b.anchor; a.N = b.textSize; a.D = b.backgroundPosition; this.n.show() } return !0
    };
    j.getBounds = function () { for (var a = new google.maps.LatLngBounds(this.d, this.d), b = this.o(), c = 0, f; f = b[c]; c++)a.extend(f.getPosition()); return a }; j.remove = function () { this.n.remove(); this.a.length = 0; delete this.a }; j.T = function () { return this.a.length }; j.o = h("a"); j.getCenter = h("d"); function y(a) { a.F = a.k.v(new google.maps.LatLngBounds(a.d, a.d)) } j.getMap = h("c");
    function t(a, b, c) { a.k.extend(t, google.maps.OverlayView); this.j = b; this.fa = c || 0; this.u = a; this.d = d; this.c = a.getMap(); this.B = this.b = d; this.t = !1; this.setMap(this.c) } j = t.prototype;
    j.onAdd = function () { this.b = document.createElement("DIV"); if (this.t) this.b.style.cssText = A(this, B(this, this.d)), this.b.innerHTML = this.B.text; this.getPanes().overlayMouseTarget.appendChild(this.b); var a = this; google.maps.event.addDomListener(this.b, "click", function () { var b = a.u.k; google.maps.event.trigger(b, "clusterclick", a.u); b.O && a.c.fitBounds(a.u.getBounds()) }) }; function B(a, b) { var c = a.getProjection().fromLatLngToDivPixel(b); c.x -= parseInt(a.p / 2, 10); c.y -= parseInt(a.h / 2, 10); return c }
    j.draw = function () { if (this.t) { var a = B(this, this.d); this.b.style.top = a.y + "px"; this.b.style.left = a.x + "px" } }; function z(a) { if (a.b) a.b.style.display = "none"; a.t = !1 } j.show = function () { if (this.b) this.b.style.cssText = A(this, B(this, this.d)), this.b.style.display = ""; this.t = !0 }; j.remove = function () { this.setMap(d) }; j.onRemove = function () { if (this.b && this.b.parentNode) z(this), this.b.parentNode.removeChild(this.b), this.b = d }; j.setCenter = e("d");
    function A(a, b) {
        var c = []; c.push("background-image:url(" + a.da + ");"); c.push("background-position:" + (a.D ? a.D : "0 0") + ";"); typeof a.e === "object" ? (typeof a.e[0] === "number" && a.e[0] > 0 && a.e[0] < a.h ? c.push("height:" + (a.h - a.e[0]) + "px; padding-top:" + a.e[0] + "px;") : c.push("height:" + a.h + "px; line-height:" + a.h + "px;"), typeof a.e[1] === "number" && a.e[1] > 0 && a.e[1] < a.p ? c.push("width:" + (a.p - a.e[1]) + "px; padding-left:" + a.e[1] + "px;") : c.push("width:" + a.p + "px; text-align:center;")) : c.push("height:" + a.h + "px; line-height:" + a.h +
            "px; width:" + a.p + "px; text-align:center;"); c.push("cursor:pointer; top:" + b.y + "px; left:" + b.x + "px; color:" + (a.M ? a.M : "black") + "; position:absolute; font-size:" + (a.N ? a.N : 11) + "px; font-family:Arial,sans-serif; font-weight:bold"); return c.join("")
    } window.MarkerClusterer = k; k.prototype.addMarker = k.prototype.q; k.prototype.addMarkers = k.prototype.C; k.prototype.clearMarkers = k.prototype.R; k.prototype.fitMapToMarkers = k.prototype.S; k.prototype.getCalculator = k.prototype.H; k.prototype.getGridSize = k.prototype.w;
    k.prototype.getExtendedBounds = k.prototype.v; k.prototype.getMap = k.prototype.getMap; k.prototype.getMarkers = k.prototype.o; k.prototype.getMaxZoom = k.prototype.I; k.prototype.getStyles = k.prototype.z; k.prototype.getTotalClusters = k.prototype.U; k.prototype.getTotalMarkers = k.prototype.V; k.prototype.redraw = k.prototype.i; k.prototype.removeMarker = k.prototype.Y; k.prototype.removeMarkers = k.prototype.Z; k.prototype.resetViewport = k.prototype.m; k.prototype.repaint = k.prototype.L; k.prototype.setCalculator = k.prototype.$;
    k.prototype.setGridSize = k.prototype.aa; k.prototype.setMaxZoom = k.prototype.ba; k.prototype.onAdd = k.prototype.onAdd; k.prototype.draw = k.prototype.draw; s.prototype.getCenter = s.prototype.getCenter; s.prototype.getSize = s.prototype.T; s.prototype.getMarkers = s.prototype.o; t.prototype.onAdd = t.prototype.onAdd; t.prototype.draw = t.prototype.draw; t.prototype.onRemove = t.prototype.onRemove;
})();


function CustomMap(o, callback) {

    o = o || {};

    var defaults = {
        settings: {
            center: new google.maps.LatLng(39.08719, 35.177914),
            zoom: 7,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.BOTTOM_CENTER
            },
            panControl: !isMobile,
            panControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            styles: [{ "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "color": "#444444" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#a1cbff" }, { "visibility": "on" }] }]
        },
        iconSrc: {
            normal: googleMapPinPath + 'map-pin.png',
            selected: googleMapPinPath + 'map-pin.png',
            myCurrentPosition: googleMapPinPath + 'location.png',
            clustering: googleMapPinPath + 'map-pin.png'
        },
    };

    this.ID = o['ID'] || '';
    this.opt = utils.extend(defaults, o['options'] || {});
    this.callback = callback;
    this.init();
}

CustomMap.prototype = {

    constructor: CustomMap,

    map: null,

    infowindow: null,

    googleMarkerClusterer: null,

    currentLocation: null,

    markersArr: [],

    speed: 100,

    zoomout: null,

    zoomin: null,

    currentMarker: null,

    bound: null,

    /* 
        dispatcher
    */

    dispatcher: function (o) {
        if (typeof stage !== 'undefined')
            stage.dispatchEvent("CustomEvent", "EVENTS_ON_MAP", o);
    },

    /* 
        markers
    */

    setMarkers: function (arr) {
        var _t = this,
            k = false;

        _t.bound = new google.maps.LatLngBounds();

        _t.deleteMarkers();

        for (var i = 0; i < arr.length; ++i) {

            var o = arr[i],
                coor = o['coor'] || '';

            if (coor != '') {
                if (coor.indexOf('.') != -1 && coor != null && coor != undefined) {
                    var a = coor.split('|'),
                        lat = a[0],
                        lng = a[1],
                        c = new google.maps.LatLng(lat, lng);

                    o['coor'] = c;

                    _t.addMarker(o);

                    _t.bound.extend(c);

                    k = true;
                } else
                    _t.markersArr.push(null);
            } else
                _t.markersArr.push(null);
        }

        _t.googleMarkerClusterer.addMarkers(_t.markersArr);

        if (k) {

            google.maps.event.trigger(_t.map, 'resize');

            setTimeout(function () {
                _t.map.fitBounds(_t.bound);
                setTimeout(function () {
                    if (_t.map.getZoom() > 18)
                        _t.map.setZoom(18);
                }, 2000);
            }, 10);
        }
    },

    addMarker: function (o) {

        var _t = this,
            marker = new google.maps.Marker({
                position: o['coor'],
                map: _t.map,
                icon: _t.opt.iconSrc[o['type'] || 'normal'],
                draggable: false,
                shadow: null,
                animation: google.maps.Animation.DROP,
                order: o['order'] || 0,
                title: o['title'] || '',
                dsc: o['desc'] || '',
                htm: o['htm']
            }),
            _infoWindowAvailable = o['infoWindowAvailable'] || 'true';

        _t.markersArr.push(marker);

        google.maps.event.addListener(marker, 'click', function () {

            var ths = this;

            if (_t.currentMarker != null)
                _t.currentMarker.setIcon(_t.opt.iconSrc['normal']);

            ths.setIcon(_t.opt.iconSrc['selected']);

            _t.currentMarker = ths;

            _t.dispatcher({ type: 'markerClicked', data: { order: ths.order, htm: ths.htm } });

            _t.infowindow.close();

            _t.moveToPointZoom(this.position, 14, { x: 0, y: 0 }, function () {

                if (!utils.responsiveControl() && _infoWindowAvailable == 'true') {
                    _t.infowindow.setContent(ths.dsc);
                    _t.infowindow.open(_t.map, ths);
                }

                _t.dispatcher({ type: 'markerClickedInfoWındow', data: { order: ths.order, dsc: ths.dsc } });

            });
        });

    },

    deleteMarkers: function () {
        var _t = this;

        if (_t.markersArr.length > 0) {
            for (i in _t.markersArr)
                if (_t.markersArr[i] != null)
                    _t.markersArr[i].setMap(null);
            _t.markersArr.length = 0;
            _t.googleMarkerClusterer.clearMarkers();
        }

        _t.markersArr.length = 0;

        _t.markersArr = [];
    },

    focusedMarkers: function( ind ){
        var _t = this,
        marker = _t.markersArr[ ind ] || '';
        if (marker != '')
			google.maps.event.trigger(marker, 'click');
    },

    /* 
        point zoom
    */

    clearInt: function () {
        var _t = this;

        if (_t.zoomout != null)
            clearInterval(_t.zoomout);

        if (_t.zoomin != null)
            clearInterval(_t.zoomin);
    },
    moveToPointZoom: function (point, zmax, offset, callback) {

        var _t = this,
            currentZoom = _t.map.getZoom(),
            currentBounds = '';

        _t.clearInt();

        _t.zoomout = setInterval(function () {

            currentBounds = _t.map.getBounds();

            if (currentBounds != '' && typeof currentBounds.contains !== 'undefined') {

                if (!currentBounds.contains(point)) {

                    _t.map.setZoom(currentZoom);

                    currentZoom--;

                } else {

                    _t.clearInt();

                    if (offset) {
                        var point1 = _t.map.getProjection().fromLatLngToPoint(point),
                            point2 = new google.maps.Point(
                                ((typeof (offset.x) == 'number' ? offset.x : 0) / Math.pow(2, zmax)) || 0,
                                ((typeof (offset.y) == 'number' ? offset.y : 0) / Math.pow(2, zmax)) || 0
                            );
                        point = _t.map.getProjection().fromPointToLatLng(new google.maps.Point(point1.x - point2.x, point1.y + point2.y));
                    }

                    _t.map.panTo(point);

                    currentZoom = _t.map.getZoom();

                    _t.zoomin = setInterval(function () {

                        if (currentZoom < zmax) {
                            _t.map.setZoom(currentZoom);
                            currentZoom++;
                        } else {
                            _t.clearInt();
                            if (typeof callback !== 'undefined')
                                callback();
                        }

                    }, _t.speed);
                }

            }

        }, _t.speed);
    },

    /* 
        my location
    */

    setCurrentLocation: function () {
        var _t = this;
        if (_t.currentLocation != null)
            _t.dispatcher({ type: 'locationShare', data: JSON.stringify(_t.currentLocation) });
        else
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords) {
                    _t.currentLocation = { lat: position.coords.latitude, long: position.coords.longitude };
                    utils.sessionStorage({ type: 'set', key: 'myCurrentPosition', value: JSON.stringify(_t.currentLocation) });
                    _t.dispatcher({ type: 'locationShare', data: JSON.stringify(_t.currentLocation) });
                } else
                    _t.currentLocation = null;
            });
    },

    getCurrentLocation: function () {
        var _t = this,
            k = utils.sessionStorage({ type: 'get', key: 'myCurrentPosition' }) || '';
        if (k !== '')
            _t.currentLocation = JSON.parse(k);
    },


    /* 
        set map settings
    */

    setMapSettings: function () {
        var _t = this;

        _t.map = new google.maps.Map(document.getElementById(_t.ID), _t.opt.settings);

        _t.infowindow = new google.maps.InfoWindow({ maxWidth: 350, content: (translation['infoWindowMap'] || 'İçerik Yükleniyor') });

        _t.googleMarkerClusterer = new MarkerClusterer(_t.map, [], {
            maxZoom: 12,
            styles: [
                {
                    textColor: 'white',
                    url: _t.opt.iconSrc['clustering'],
                    height: 59,
                    width: 49,
                    anchor: [0, 0]
                }
            ]
        });

        _t.map.addListener('click', function () {
            _t.dispatcher({ type: 'mapClicked' });
        });

    },
    init: function () {
        var _t = this;
        _t.setMapSettings();
        _t.getCurrentLocation();
    }
};

// Teslim Alınabilecek Mağazalar
var storesAvaiblableDelivery = {
    el: {
        btn: '.pay-click-collect .fake-dropdown', // ilk mapin trigger edilmesi
        closeBtn: '.map-view-close-btn',
        wrapper: '.modal-dialog', // en dış kapsayıcı class buna gelecek
        itemBtn: '.srv-item .srv-map button', // yuklenen itemlar ile map içerisine marker gonderilmesi
        title: '.srv-name',
        mapContent: '.map-view-content', // tıklanan item html buna append edilir
        mapContentSel: '.map-view-content .btn-selection'
    },
    cls: {
        activeted: 'map-view-activeted'
    },
    map: null,
    loaded: false,

    currentLocation: null,
    setCurrentLocation: function () {
        var _t = this;
        if (_t.currentLocation != null);
        else
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords) {
                    _t.currentLocation = { lat: position.coords.latitude, long: position.coords.longitude };
                    utils.sessionStorage({ type: 'set', key: 'myCurrentPosition', value: JSON.stringify(_t.currentLocation) });
                } else
                    _t.currentLocation = null;
            });
    },
    getCurrentLocation: function () {
        var _t = this,
            k = utils.sessionStorage({ type: 'get', key: 'myCurrentPosition' }) || '';
        if (k !== '')
            _t.currentLocation = JSON.parse(k);
    },
    addEvent: function () {
        var _t = this,
            wrapper = $(_t.el.wrapper);

        $(_t.el.closeBtn)
            .on('click', function () {
                wrapper.removeClass(_t.cls['activeted']);
            });

        $(_t.el.btn)
            .on('click', function () {
                _t.initPlugins();
            });

        $(_t.el.itemBtn)
            .on('click', function () {
                var ths = $(this),
                    parentNode = ths.parents('[data-coor]'),
                    coor = parentNode.attr('data-coor') || '';
                if (coor !== '') {
                    var title = parentNode.find(_t.el.title).text() || '',
                        htm = parentNode[0].outerHTML || '',
                        arr = [{
                            order: 0,
                            title: title,
                            coor: coor,
                            infoWindowAvailable: 'true'
                        }];
                        
                    /* 
                        location varsa
                    */    
                    if (_t.currentLocation != null) {
                        var lat = _t.currentLocation.lat || '',
                            long = _t.currentLocation.long || '';
                        if (lat != '' && long != '') {
                            arr.push({
                                order: -1,
                                title: 'my location',
                                coor: lat + '|' + long,
                                infoWindowAvailable: 'true',
                                type: 'myCurrentPosition'
                            });
                        }
                    }

                    _t.map.setMarkers(arr);

                    $(_t.el.mapContent).html(htm);

                    wrapper.addClass(_t.cls['activeted']);

                    $(_t.el['mapContentSel']).on('click', function(){
                        wrapper.removeClass(_t.cls['activeted']);
                        parentNode.find('.btn-selection').trigger('click');
                    });
                }
            });
    },
    initPlugins: function () {
        var _t = this;
        if (!_t.loaded) {
            _t.loaded = true;
            _t.setCurrentLocation();
            // if( ! googleMapAPILoaded){
            //     googleMapAPILoaded = true;
            //     $.getScript(SITE_CONFIG.general.googleMapAPI + googleMapAPIKey, function () {
            //         _t.map = new CustomMap({ ID: 'Map' });
            //     });
            // }else{
            _t.map = new CustomMap({ ID: 'Map' });
            // }
        }
    },
    init: function () {
        var _t = this;
        _t.getCurrentLocation();
        _t.addEvent();
    }
};


/* 
    yetkili servisler
*/

var stores = {
    el: {
        wrp: '.page-services',
        item: '.srv-list .srv-item',
        btn: '.srv-list .srv-item .srv-link',
        title: '.srv-name',
        closeBtn: '.map-view-close-btn',
        list: '.map-view-content .srv-item',
        targetSlider: '.map-view-content .swiper-wrapper'
    },
    cls: {
        activeted: 'map-view-activeted',
        selected: 'selected'
    },
    map: null,
    loaded: false,
    currentLocation: null,
    setCurrentLocation: function () {
        var _t = this;
        if (_t.currentLocation != null);
        else
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords) {
                    _t.currentLocation = { lat: position.coords.latitude, long: position.coords.longitude };
                    utils.sessionStorage({ type: 'set', key: 'myCurrentPosition', value: JSON.stringify(_t.currentLocation) });
                } else
                    _t.currentLocation = null;
            });
    },
    getCurrentLocation: function () {
        var _t = this,
            k = utils.sessionStorage({ type: 'get', key: 'myCurrentPosition' }) || '';
        if (k !== '')
            _t.currentLocation = JSON.parse(k);
    },
    setMarkers: function () {
        var _t = this,
            list = document.querySelectorAll(_t.el.list),
            arr = [];

        utils.forEach(list, function (ind, item) {

            item.setAttribute('data-order', ind);

            var loc = item.getAttribute('data-coor') || '';
            if (loc != '') {
                var htm = item.innerHTML || '';
                arr.push({
                    order: ind,
                    title: item.querySelector(_t.el.title).innerHTML || '',
                    coor: loc,
                    desc: '<div class="tooltip-content">' + htm + '</div>',
                    htm: htm,
                    infoWindowAvailable: 'true'
                });
            } else
                arr.push(null);
        });

        /* 
            location varsa
        */
        if (_t.currentLocation != null) {
            var lat = _t.currentLocation.lat || '',
                long = _t.currentLocation.long || '';
            if (lat != '' && long != '') {
                arr.push({
                    order: -1,
                    title: 'my location',
                    coor: lat + '|' + long,
                    infoWindowAvailable: 'true',
                    type: 'myCurrentPosition'
                });
            }
        }

        _t.map.setMarkers(arr);
    },
    addEvent: function () {
        var _t = this,
            wrp = document.querySelector(_t.el.wrp),
            btn = document.querySelectorAll(_t.el.btn),
            closeBtn = document.querySelectorAll(_t.el.closeBtn);

        /* 
            itemClicked
        */
       utils.forEach(btn, function (ind, item) {
        item.onclick = function(){
            var ths = $( this );
                ths.parents('.srv-item').addClass( _t.cls['selected'] ).siblings().removeClass( _t.cls['selected'] );

            wrp.classList.add(_t.cls['activeted']);

            if( utils.responsiveControl() ){
                
                _t.updateSwiper();
                setTimeout(function(){
                    _t.focusedSwiper( ths.parents('.srv-item').attr('data-order') || 0 );    
                }, 50);
                
            }else{

                stores.focusedMarker( ths.parents('.srv-item').attr('data-order') || 0 );
            }            
        }
       });

       utils.forEach(closeBtn, function (ind, item) {
        item.onclick = function(){
            wrp.classList.remove(_t.cls['activeted']);
        }
       }); 

    },
    focusedSwiper: function( ind ){
        var _t = this,
            elm = $('.map-view-container .swiper-container');
        if( elm.length > 0 )
            elm.get(0).focused(ind);
    },
    updateSwiper: function(){
        var _t = this,
            elm = $('.map-view-container .swiper-container');
        if( elm.length > 0 )
            elm.get(0).update();

    },
    focusedMarker: function( ind ){
        var _t = this;
        if( _t.map != null )
            _t.map.focusedMarkers( ind )
    },
    setHtml: function(){
        /* 
            ajx ile dolan listeyi slider alanınına klonlanması
        */
        var _t = this,
            htm = $( _t.el.item )
            .map(function(ind){
                return '<li data-order="'+ ind +'" class="swiper-slide">' + $( this ).get( 0 ).outerHTML + '</li>';
            })
            .get()
            .join(' ');

        $(_t.el.targetSlider).html( htm );
    },
    responseAjxRequest: function(){
        var _t = this;
        _t.setHtml();
        _t.setMarkers();
    },
    initPlugins: function () {
        var _t = this;
        if (!_t.loaded) {
            _t.loaded = true;
            _t.setCurrentLocation();
            // if( ! googleMapAPILoaded){
            //     googleMapAPILoaded = true;
            //     $.getScript(SITE_CONFIG.general.googleMapAPI + googleMapAPIKey, function () {
            //         _t.map = new CustomMap({ ID: 'Map' });
            //         _t.responseAjxRequest();
            //         _t.addEvent();
            //     });
            // }else{
            _t.map = new CustomMap({ ID: 'Map' });
            _t.responseAjxRequest();
            _t.addEvent();
            // }
        }
    },
    init: function () {
        var _t = this;
        if( $( _t.el.wrp ).length > 0 ){
            _t.getCurrentLocation();
            _t.initPlugins();
        }
    }
};

function ON_EVENTS_ON_MAP( o ){
    var type = o['type'] || '',
        data = o['data'] || {};

    switch (type) {
        case 'markerClicked':
            console.log( data );
            break;
        default:
            break;
    }
}
stage.addEventListener("CustomEvent", [{ type: 'EVENTS_ON_MAP', handler: 'ON_EVENTS_ON_MAP' }]);



function ON_SERVICE_LIST_MAP( o ){
    var ID = $( o['ID'] || '' ),
        type = o['type'] || '';
    
        if( ID.parents('.map-view-container').length > 0 )
            switch (type) {
                case 'slideChangeTransitionEnd':
                    stores.focusedMarker( (o['swiper'] || {}).activeIndex || 0 );
                    break;
            
                default:
                    break;
            }
}
stage.addEventListener("CustomEvent", [{ type: 'SWIPER_ACTIVE_ELEMENT', handler: 'ON_SERVICE_LIST_MAP' }]);