if(typeof mainSliderLoop === 'undefined')
    mainSliderLoop = false;

if(typeof pikadayI18n === 'undefined')
    pikadayI18n = {
        previousMonth : 'Previous Month',
        nextMonth     : 'Next Month',
        months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
        weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
        weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
    };

var SITE_CONSTANT = {
    today: new Date(),
    yearFromBack: 100,
    yearToFuture: 0,
    dayToFuture: 0,
    futureLimit: 7,
    minPasswordLength: 6
};
var SITE_CONFIG = {
    general: {
        responsive: '(max-width: 960px)', /* kaç px de responsive geçecegi */
        regex: {
            typ1: /[^a-zA-ZiiIgüsöçIGÜSÖÇ\s]+/, /* sadece harf */
            typ2: /[^0-9\s]+/, /* sadece rakam */
            typ3: /[^a-zA-ZiiI0-9güsöçIGÜSÖÇ\s]+/ /* harf rakam karisik */
        },
        preloader: '<svg width="100" height="100" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#2e2a7e" stroke-width="1" r="30" stroke-dasharray="141.37166941154067 49.12388980384689"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle><path fill-rule="evenodd" clip-rule="evenodd" d="M43.1923 44.7442H56.0048C62.411 46.3577 62.411 54.6626 56.0048 56.0795H39.0018V53.1394H54.7136C59.5046 52.9623 59.7118 47.6489 54.7136 47.3797H46.035V52.4735H43.1923V44.7442ZM39 41H57.9105C67.3632 43.3804 67.3632 57.5248 57.9105 59.613H39V56.749H56.5662C63.8722 55.1373 63.8722 45.6192 56.5662 43.7789H41.956V52.4788H39V41V41Z" fill="#D12D26"/></svg>',
        clipboard: '<input type="text" id="clipboard" value="{{VAL}}" tabindex="-1" aria-hidden="true" readonly />',
        googleMapAPI: '//maps.googleapis.com/maps/api/js?libraries=places,geometry&v=weekly&key=',
        url: '',
        share: {
            fb: 'https://www.facebook.com/sharer/sharer.php?u={{URL}}',
            tw: 'https://twitter.com/intent/tweet?text=&url={{URL}}'
        }
    },
    plugin: {
        
        /* 
            swiper config
        */
        swiper: {
            defaultOpt: {
                videoStretching: 'responsive'
            },
            main: {
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            },
            compare: {
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: '.swiper-compare-next',
                    prevEl: '.swiper-compare-prev',
                }
            },
            innerSlider: {
                slidesPerView: 1,
                effect: isMobile ? 'slide' : 'fade',
                pagination: {
                    el: '.swiper-pagination-inner',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next-inner',
                    prevEl: '.swiper-button-prev-inner',
                }
            },
            categories: {
                slidesPerView: 'auto',
                freeMode: true,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    hide: false,
                    draggable: true,
                    dragSize: 300
                },
                pagination: {
                    el: '.swiper-pagination-inner',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            },
            singleItem: {
                slidesPerView: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            },
            pdpGallery: {
                slidesPerView: 1,
                zoom:{
                    maxRatio: 2
                },
                pagination: {
                    el: '.swiper-controls .swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: '.swiper-controls .swiper-button-next',
                    prevEl: '.swiper-controls .swiper-button-prev',
                }
            },
            mainSlider: {
                slidesPerView: 1,
                loop: mainSliderLoop,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    dynamicBullets: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
                // autoplay: {
                //     delay: 5000,
                //     disableOnInteraction: false
                // }
            }
        },
        pikaday: {
            defaults: {
                i18n: pikadayI18n,
                firstDay: 1,
                formatStrict: true,
                format: 'DD/MM/YYYY',
                toString: function(date, format) {
                    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
                },
                parse: function(dateString, format) {
                    var parts = dateString.split('/');
                    // new Date(Year, Month, Day)
                    return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
                }
            },
            sets: {
                birthday: {
                    maxDate: new Date(),
                    yearRange: [SITE_CONSTANT.today.getFullYear() - SITE_CONSTANT.yearFromBack, SITE_CONSTANT.today.getFullYear()]
                },
                futureDate: {
                    minDate: new Date(Date.now() + SITE_CONSTANT.dayToFuture * 24 * 60 * 60 * 1000),
                    maxDate: new Date(Date.now() + SITE_CONSTANT.futureLimit * 24 * 60 * 60 * 1000),
                    yearRange: [SITE_CONSTANT.today.getFullYear()]
                }
            }
        },
        cleave: {
            // phone1: {
            //     delimiter: '',
            //     blocks: [2, 9],
            //     prefix: '05',
            //     numericOnly: true,
            //     noImmediatePrefix: true,
            //     delimiterLazyShow: true
            // },
            // phone2: {
            //     delimiter: '',
            //     blocks: [3, 10],
            //     prefix: '+90',
            //     numericOnly: true,
            //     noImmediatePrefix: true,
            //     delimiterLazyShow: true
            // },
            // phone3: {
            //     delimiter: '',
            //     blocks: [1, 9],
            //     prefix: '5',
            //     numericOnly: true,
            //     noImmediatePrefix: true,
            //     delimiterLazyShow: true
            // },
            otp: {
                blocks: [6],
                numericOnly: true
            },
            amount: {
                numeral: true,
                numeralPositiveOnly: true,
                numeralDecimalMark: ',',
                delimiter: '.'
            },
            birthday: {
                date: true,
                datePattern: ['d', 'm', 'Y'],
                dateMin: (SITE_CONSTANT.today.getFullYear() - SITE_CONSTANT.yearFromBack)+'-01-01',
                dateMax: SITE_CONSTANT.today.getFullYear()+'-'+(SITE_CONSTANT.today.getMonth()+1)+'-'+SITE_CONSTANT.today.getDate()
            },
            futureDate: {
                date: true,
                datePattern: ['d', 'm', 'Y'],
                dateMin: SITE_CONSTANT.today.getFullYear()+'-'+(SITE_CONSTANT.today.getMonth()+1)+'-'+(SITE_CONSTANT.today.getDate() + SITE_CONSTANT.dayToFuture),
                dateMax: (SITE_CONSTANT.today.getFullYear() + SITE_CONSTANT.yearToFuture)+'-'+(SITE_CONSTANT.today.getMonth()+1)+'-'+(SITE_CONSTANT.today.getDate() + SITE_CONSTANT.futureLimit)
            }
        },
        countdown: {
            hour: '<span class="digit">{hnn}</span>{sep}<span class="digit">{mnn}</span>{sep}<span class="digit">{snn}</span>',
            day: '<span class="digit day">{dnn}<i>{{DAY}}</i></span><span class="digit">{hnn}</span>{sep}<span class="digit">{mnn}</span>{sep}<span class="digit">{snn}</span>'
        }
    },
    animations: {
        mobile:{
            // headerTransform:{
            //     trigger: {
            //         trigger: '#wrapper', 
            //         start: 50, 
            //         toggleClass: false,
            //         onEnter: function(){
            //             $('body').toggleClass('header-opaque');
            //         },
            //         onLeaveBack: function(){
            //             $('body').toggleClass('header-opaque');
            //         }
            //     }
            // },
            pageCover: {
                trigger: {
                    start: 'top top+=50'
                },
                tween: {
                    y: 100
                }
            },
            MSPrd: {
                trigger: {
                    trigger: '#main-slider',
                    start: 'top top'
                },
                tween: {
                    y: 100
                }
            },
            inView:{
                trigger: {
                    toggleClass: false,
                    start: '40% 100%',
                    onEnter: function(ths){
                        $(ths.trigger).addClass('in-view');
                    }
                }
            },
            PDPGallery: {
                trigger: {
                    start: 'top top+=95'
                },
                tween: {
                    y: 150,
                    opacity: 0,
                    scale: .9
                }
            },
            PDPBreadcrump:{
                trigger: {
                    start: 'top top+=50'
                },
                tween: {
                    opacity: 0
                }
            },
            PDPPurchase:{
                trigger: {
                    start: 'bottom top',
                    toggleClass: false,
                    onEnter: function(ths){
                        var tH = $(ths.trigger).innerHeight();
                        $(ths.trigger).css({'min-height': tH});
                        $('body').addClass('pdp-purchase-ready');
                    },
                    onLeaveBack: function(){
                        $('.pdp-purchase .btn-switch.activated').trigger('click');
                        $('body').removeClass('pdp-purchase-ready');
                    }
                }
            },
            compareHeader:{
                trigger: {
                    trigger: '.compare-holder',
                    start: 'top top', 
                    toggleClass: false,
                    onEnter: function(ths){
                        $(ths.trigger).toggleClass('title-block');
                    },
                    onLeaveBack: function(ths){
                        $(ths.trigger).toggleClass('title-block');
                    }
                }
            }
        },
        desktop:{
            // headerTransform:{
            //     trigger: {
            //         trigger: '#wrapper', 
            //         start: 50, 
            //         toggleClass: false,
            //         onEnter: function(){
            //             $('body').toggleClass('header-opaque');
            //         },
            //         onLeaveBack: function(){
            //             $('body').toggleClass('header-opaque');
            //         }
            //     }
            // },
            pageCover: {
                trigger: {
                    start: 'top top+=50'
                },
                tween: {
                    y: 100
                }
            },
            MSPrd: {
                trigger: {
                    trigger: '#main-slider',
                    start: 'top top'
                },
                tween: {
                    y: 100
                }
            },
            inView:{
                trigger: {
                    toggleClass: false,
                    start: '40% 100%',
                    onEnter: function(ths){
                        $(ths.trigger).addClass('in-view');
                    }
                }
            },
            compareHeader:{
                trigger: {
                    trigger: '.compare-holder',
                    start: 'top top', 
                    toggleClass: false,
                    onEnter: function(ths){
                        $(ths.trigger).toggleClass('title-block');
                    },
                    onLeaveBack: function(ths){
                        $(ths.trigger).toggleClass('title-block');
                    }
                }
            },
            PDPPurchase:{
                trigger: {
                    start: 'bottom top',
                    toggleClass: false,
                    onEnter: function(ths){
                        var tH = $(ths.trigger).innerHeight();
                        $(ths.trigger).css({'min-height': tH});
                        $('body').addClass('pdp-purchase-ready');
                    },
                    onLeaveBack: function(){
                        $('.pdp-purchase .btn-switch.activated').trigger('click');
                        $('body').removeClass('pdp-purchase-ready');
                    }
                }
            }
        }
    }
};