/*

----------
  USAGE
----------

stage.addEventListener("CustomEventClass", [{type:"CUSTOM_EVENT_TYPE", handler:"customEventHandler"}]);

stage.addEventListener("CustomEventClass", 
	[
		{type:"CUSTOM_EVENT_TYPE_1", handler:"customEventHandler1"},
		{type:"CUSTOM_EVENT_TYPE_2", handler:"customEventHandler2"}
	]
);

stage.removeEventListener("CustomEventClass");

stage.removeEventListener("CustomEventClass", "CUSTOM_EVENT_TYPE");

stage.dispatchEvent("CustomEventClass", "CUSTOM_EVENT_TYPE", vars);

function customEventHandler(vars)
{
	// do something cool...
}


*/

(function(f) {
    f.stage = {
        addEventListener: function(d, c) {
            var attachNewEvent = function(a) {
                a.events.push(d);
                a.handlers.push(c)
            };
            if (0 < this.events.length) {
                var a = 0;
                a: for (; a < this.events.length; ++a)
                    if (this.events[a] == d) {
                        var b = a;
                        break a
                    }
                if (0 <= b)
                    for (b = 0; b < c.length; ++b) this.handlers[a].push(c[b]);
                else attachNewEvent(this)
            } else attachNewEvent(this)
        },
        removeEventListener: function(d, c) {
            var a = 0;
            a: for (; a < this.events.length; ++a)
                if (this.events[a] == d) {
                    if (void 0 == c || "" == c || null == c) this.events.splice(a, 1), this.handlers.splice(a,
                        1);
                    else
                        for (var a = this.handlers[a], b = 0; b < a.length; ++b) a[b].type == c && a.splice(b, 1);
                    break a
                }
        },
        dispatchEvent: function(d, c, a) {
            if (0 < this.events.length)
                for (var b = 0; b < this.events.length; ++b)
                    if (this.events[b] == d && 0 < this.handlers.length)
                        for (var g = this.handlers[b], e = 0; e < g.length; ++e) {
                            var h = g[e];
							if (h.type == c){
                                if( typeof f[h.handler] !== 'undefined' )
                                    f[h.handler](a);
                            } 
                        }
        },
        events: [],
        handlers: []
    }
})(window);