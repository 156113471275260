var pdpControls = {
    el: {
        main: '.pdp'
    },
    purchase: { 
        el: {
            prc: '.pdp-purchase',
            nav: '#pdp-tab-steps'
        },
        steps: function(){
            var _t = this, sct, id, title = $('.pdp-name .title').text();

            $(_t.el['prc'] + ' .t').text(title);

            $('[data-acc-section]').each(function(){
                sct = $(this).attr('data-acc-section');
                id = $(this).attr('id');
                $(_t.el['prc'] + ' .pdp-tab-steps').append('<a href="#' + id + '">' + sct + '</a>');
            });

            $(_t.el['nav'] + ' a').on('click', function(e){
                e.preventDefault();
                id = $(this).attr('href');
                sct = $(id).offset().top + 5;

                if( ! $(id).hasClass('active'))
                    $(id + ' > .acc-item button').trigger('click');
                
                $(_t.el['prc'] + ' .btn-switch').trigger('click');
                utils.pageScroll({top: sct});
            });
        },
        nav: function(){
            var _t = this;
            observer = new IntersectionObserver(function(entries, observer){
                for(i in entries){
                    if(entries[i].intersectionRatio > 0){
                        $(_t.el['nav'] + ' a.active').removeClass('active');
                        $(_t.el['nav'] + ' a[href="#' + entries[i].target.id + '"]').addClass('active');
                    }
                }
            });
            
            $('[data-acc-section]').each(function(){
                observer.observe(this);
            });
        },
        init: function(){
            var _t = this;

            _t.steps();
            _t.nav();
        }
    },
    ftrTab: {
        el: {main: '.ftc-tab-item', tab: '.ftc-tabs button', item: '.ftc-item'},
        init: function(){
            var _t = this, prn, inx;

            $(_t.el['main'] + ' ' + _t.el['tab']).on('click', function(){
                inx = $(this).index();
                prn = $(this).parents(_t.el['main']);
                $(_t.el['item'] + '.active', prn).removeClass('active');
                $(_t.el['item'] + ':eq(' + inx + ')', prn).addClass('active');
                $(_t.el['tab'] + '.active', prn).removeClass('active');
                $(this).addClass('active');
            });
        }
    },
    cocoen: function(){
        var vid = [];
        if(utils.detectEl($('.cocoen')[0])){
            $.cachedScript(extJSPath + 'cocoen.min.js').done(function(script, textStatus) {
                $('.cocoen').each(function(e){
                    lazy.update();
                    new Cocoen(this);

                    if($('video', this).length > 0){
                        vid[e] = {first: {ready: false, obj: $('.cocoen-first video', this)}, last: {ready: false, obj: $('> video', this)}};

                        vid[e]['first']['obj'].on('canplay', function(){
                            vid[e]['first']['ready'] = true;

                            if(vid[e]['last']['ready']){
                                vid[e]['first']['obj'][0].play();
                                vid[e]['last']['obj'][0].play();
                            }
                        });

                        vid[e]['last']['obj'].on('canplay', function(){
                            vid[e]['last']['ready'] = true;

                            if(vid[e]['first']['ready']){
                                vid[e]['first']['obj'][0].play();
                                vid[e]['last']['obj'][0].play();
                            }
                        });
                    }
                });
            });
        }
    },
    tss: {
        el: {main: '.tss'},
        obj: {},
        loadScripts: function(){
            stage.addEventListener("CustomEvent", [{ type: 'TSS_JS_LOADED', handler: 'ON_TSS_JS_LOADED' }]);
            $.cachedScript(extJSPath + 'threesixty.min.js').done(function(script, textStatus) {
                $.cachedScript(extJSPath + 'nouislider.min.js').done(function(script, textStatus) {
                    stage.dispatchEvent("CustomEvent", 'TSS_JS_LOADED', {});
                });
            });
        },
        lazy: function(){
            var _t = this, ths;

            new LazyLoad({
                elements_selector: '.tss-plh',
                callback_reveal: function (elm) {
                    ths = $(elm).parents(_t.el['main']).attr('data-tss');

                    if(typeof noUiSlider !== 'undefined')
                        _t.trigger(ths);
                    else
                        _t.obj[ths]['init'] = true;
                }
            });
        },
        collect: function(){
            var _t = this, order;

            $(_t.el['main']).each(function(e){
                order = 'tss' + e;
                _t.obj[order] = {
                    main: {},
                    slider: {},
                    bar: {},
                    tf: $(this).attr('data-total-frames'),
                    fp: $(this).attr('data-frame-path'),
                    tsready: false,
                    init: false
                };
                _t.obj[order]['main'] = $(this);
                _t.obj[order]['bar'] = $('.tss-bar', this)[0];
                _t.obj[order]['slider'] = $('.tss-slider', this);
                $(this).attr('data-tss', order);
            });
        },
        trigger: function(ths){
            var _t = this;

            _t.obj[ths]['slider'] = _t.obj[ths]['slider'].ThreeSixty({
                totalFrames: _t.obj[ths]['tf'],
                imgList: '.tss-images',
                progress: '.tss-spinner',
                imagePath: _t.obj[ths]['fp'],
                ext: '.jpg',
                width: 1280,
                height: 850,
                responsive: true,
                disableSpin: true,
                navigation: false,
                drag: false,
                onReady: function(){
                    if( ! _t.obj[ths]['tsready']){
                        _t.obj[ths]['tsready'] = true;
                        noUiSlider.create(_t.obj[ths]['bar'], {
                            start: [0],
                            step: 1,
                            range: {
                                'min': [0],
                                'max': [(_t.obj[ths]['tf'] - 1)]
                            }
                        });
                        _t.obj[ths]['main'].addClass('tss-loaded');
                        _t.obj[ths]['bar'].noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                            _t.obj[ths]['slider'].gotoAndPlay(parseFloat(values[0]));
                        });
                    }
                }
            });
        },
        init: function(){
            var _t = this;

            if(utils.detectEl($(_t.el['main'])[0])){
                _t.collect();
                _t.lazy();
                _t.loadScripts();
            }
        }
    },
    swipeToStart: function(){
        var vid, ths;
        if(utils.detectEl($('.ftc-swipe')[0])){
            $('.ftc-swipe').each(function(){
                ths = $(this);
                vid = ths.parents('.ftc-media').find('video');

                $('button', this).off().on('click touchstart', function(){
                    if(ths.hasClass('playing')){
                        vid[0].pause();
                        ths.removeClass('playing');
                        vid.off('ended');
                    }else{
                        vid[0].play();
                        ths.addClass('playing');
                        vid.on('ended', function(){
                            ths.removeClass('playing');
                        });
                    }
                });
            }); 
        }
    },
    ar360: {
//         el: {
//             v360: {main: '.pdp-360', gal: '.pdp .pdp-gallery'},
//             ar: {main: '.pdp-ar', btn: '.ar-content .ar-button'}
//         },
//         uri: SITE_CONFIG.general.holonext,
//         template: {
//             v360: '<div id="view-360"><model-viewer id="model-viewer" sceneId="{{SRC}}" auto-rotate style="background-color: unset;"></model-viewer><script src="https://holonext.azurewebsites.net/public/js/hn-sdk-embed-v5.js"></script></div>',
//             ar: '<a class="btn-ar-hidden" rel="ar" href="{{HREF}}"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" /></a>'
//         },
//         getUrls: function(){
//             var _t = this, urls, loc = window.location.href.replace(window.location.search, '');
// console.log('Requesting URL: ' + _t.uri.replace('{{LOC}}', loc));
//             utils.ajx({url: _t.uri.replace('{{LOC}}', loc), dataType: 'json', crossDomain: true}, function(data){
//                 console.log(data);
//                 if(data['type'] == 'success'){
//                     urls = JSON.parse(data['val']);
//                     _t.prepare(urls['body']);
//                 }
//             });
//         },
//         prepare: function(urls){
//             var _t = this, isIOS = new BrowserDetector().isIOS(), isAndroid = new BrowserDetector().isAndroid(), href;

//             if(isIOS || isAndroid){
//                 href = (isIOS) ? urls['usdzModel'] : urls['modelViewer'];
//                 $(_t.el['ar']['btn']).append(_t.template['ar'].replace('{{HREF}}', href));
//                 var sct;
//                 $('body').addClass('ar-button-ready');
//                 $('#btn-ar').on('click', function(){
//                     if( ! $('#pdp-features').hasClass('active'))
//                         $('#pdp-features > .acc-item button').trigger('click');
                    
//                     setTimeout(function(){
//                         sct = $('.ar-content').offset().top;
//                         utils.pageScroll({top: sct});
//                     }, 300);
//                 });
//             }else{
//                 $(_t.el['v360']['main'] + ' .btn-360').on('click', function(){
//                     $(_t.el['v360']['gal']).prepend(_t.template['v360'].replace('{{SRC}}', urls['sceneId'])).addClass('view-360-ready');
//                 });
//             }
//         },
        init: function(){
            var _t = this, sct;

            // if(utils.detectEl($(_t.el['ar']['main'])[0]))
            //     _t.getUrls();
            if(isMobile && utils.detectEl($('.ar-content')[0]))
                $('#btn-ar').on('click', function(){
                    if( ! $('#pdp-features').hasClass('active'))
                        $('#pdp-features > .acc-item button').trigger('click');
                    
                    setTimeout(function(){
                        sct = $('.ar-content').offset().top - 70;
                        utils.pageScroll({top: sct});
                    }, 300);
                });
        }
    },
    reviews: {
        readMore: function(){
            if(utils.detectEl($('.rvw-item-more')[0])){
                $('.rvw-item-more').on('click', function(){
                    $(this).parents('.rvw-item-less').removeClass('rvw-item-less');
                });
            }
        },
        reviewMedia: function(){
            if(utils.detectEl($('.rvw-item-media')[0]))
                viewMedia.thumb('.rvw-item-media');
        },
        postComment: {
            el: {quiz: '.pcm-quiz', item: '.pcm-item'},
            selection: function(){
                var _t = this, prn;
                $(_t.el['quiz'] + ' .btn').on('click', function(){
                    prn = $(this).parents(_t.el['item']);
                    $('.selected', prn).removeClass('selected');
                    $(this).addClass('selected');
                });
            },
            init: function(){
                var _t = this;

                _t.selection();
            }
        },
        init: function(){
            var _t = this;

            _t.readMore();
            _t.reviewMedia();
            _t.postComment.init();
        }
    },
    pdpScroll: function(){
        $('.pdp-bottom').on('click', function(){
            utils.pageScroll({top: $('.pdp-tab').offset().top});
        });
    },
    stockReminder: function(){
        $('#modal-stock-reminder').on('show.bs.modal', function (e) {
            $('#stock-reminder .sr-prd-media').html('<img srcset="' + $('.pdp .pdp-gallery .swiper-wrapper .swiper-slide:first img').attr('data-srcset') + '" />');
            $('#stock-reminder .sr-prd-title').text($('.pdp .pdp-name .title').text());  
        });
    },
    reviewScroll: function(){
        $('.pdp-reviews .qty').on('click', function(e){
            e.preventDefault();
            var id = '#pdp-allreviews';
            sct = $(id).offset().top - 65;

            if( ! $(id).hasClass('active'))
                $(id + ' > .acc-item button').trigger('click');

            utils.pageScroll({top: sct});
        });
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($('.page-product-detail')[0])){
            // PAGE LOAD
            _t.purchase.init();
            _t.ar360.init();
            _t.pdpScroll();
            _t.stockReminder();
            _t.reviewScroll();

            // AJAX
            _t.reviews.init();
        }

        _t.swipeToStart();
        _t.ftrTab.init();
        _t.cocoen();
        _t.tss.init();
    }
};

function ON_TSS_JS_LOADED(o){
    for(i in pdpControls.tss.obj)
        if(pdpControls.tss.obj[i]['init'])
            pdpControls.tss.trigger(i);
}
