var purchaseControls = {
    clubs: {
        hiddenForm: {
            el: {main: '.club-form-hidden'},
            bind: function(){
                var _t = this, prn;

                $(_t.el['main'] + ' .club-info .btn').on('click', function(){
                    prn = $(this).parents(_t.el['main']).addClass('visible');
                });
            },
            init: function(){
                var _t = this;

                if(utils.detectEl($(_t.el['main'])[0]))
                    _t.bind();
            }
        },
        init: function(){
            var _t = this;

            _t.hiddenForm.init();
        }
    },
    payConfirm: function(){
        if(isMobile)
            $('.cart-sum-confirm').insertAfter('.pay-body');
    },
    invAddress: function(){
        $('.pay-address-diff input').on('click', function(){
            if($(this).is(':checked'))
                $('.pay-address').addClass('inv-address-ready');
            else
                $('.pay-address').removeClass('inv-address-ready');
        });
    },
    newAddress: function(){
        $('#modal-select-address').modal('hide');
        $('#modal-add-new-address').modal('show');
    },
    savedCards:{ 
        el: {main: '#pay-payment-card', new: '.new-card input', item: '.rl-item'},
        bind: function(){
            var _t = this;

            $(_t.el['main'] + ' ' + _t.el['item']+ ' input').on('click', function(){
                if($(_t.el['main'] + ' ' + _t.el['new']).is(':checked'))
                    $(_t.el['main']).addClass('card-form-ready');
                else
                    $(_t.el['main']).removeClass('card-form-ready');
            });

            if($(_t.el['main'] + ' ' + _t.el['item']).length == 1)
                $(_t.el['main'] + ' ' + _t.el['new']).trigger('click');
            else
                $(_t.el['main']).addClass('saved-cards-ready');
        },
        init: function(){
            var _t = this;

            _t.bind();
        }
    },
    addressTypes: function(){
        var inx, prn;

        $('.adr-types .radio-switch input').on('click', function(){
            prn = $(this).parents('.adr-holder');
            inx = $(this).parents('[class^="rs-"]').index();
            $('.adr-forms .adr-form.active', prn).removeClass('active');
            $('.adr-forms .adr-form:eq(' + inx + ')', prn).addClass('active');
        });
    },
    cartSum: function(){
        if(isMobile){
            $('.cart-sum-total').on('click', function(){
                bdy.toggleClass('cart-sum-opened');
                $('#overlay').off('click').on('click', function(){
                    bdy.removeClass('cart-sum-opened');
                });
            });
        }
    },

    init: function(){
        var _t = this;

        if(utils.detectEl($('.page-cart')[0])){
            _t.clubs.init();
            _t.cartSum();
        }else if(utils.detectEl($('.page-payment')[0])){
            _t.payConfirm();
            _t.invAddress();
            _t.savedCards.init();
            _t.cartSum();
        }

        if(utils.detectEl($('#modal-add-new-address')[0]) || utils.detectEl($('#modal-guest-address')[0]))
            _t.addressTypes();
    }
};

// Purchase Multicard Counter
var MCPayCounter = {
    el: {main: '.mcard-step-complete'},
    reveal: function(){
        var _t = this, time = $(_t.el['main'] + ' .time').attr('data-bind');

        time = (time) ? new Date(time) : _t.calcTime();

        $.cachedScript(extJSPath + 'jquery.countdown.min.js').done(function(script, textStatus) {
            _t.setCounter(time);
        });
    },
    calcTime: function(){
        var _t = this, timer = $(_t.el['main'] + ' .time'), mins, time;

        mins = parseFloat(timer.attr('data-time'));
        time = new Date();
        time.setMinutes(time.getMinutes() + mins);
        timer.attr('data-bind', time);

        return time;
    },
    setCounter: function(time){
        var _t = this;

        $(_t.el['main'] + ' .time').countdown({
            until: time, 
            layout: SITE_CONFIG.plugin.countdown.hour,
            timeSeparator: '<span class="sep">:</span>',
            onExpiry: function(){
                console.log('TIME IS OVER!');
            }
        });
    }
};