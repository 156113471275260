var dealCountdown = {
    el: {main: '#deal-countdown', time: '.time'},
    observer: {},
    template: SITE_CONFIG.plugin.countdown,
    loaded: false,
    build: function(){
        var _t = this, time, str, tmp = _t.template['hour'], day;

        $(_t.el['main'] + ' ' + _t.el['time']).each(function(){
            day = $(this).attr('data-day-label');
            str = $(this).attr('data-date').split(' ');
            time = str[0].split('-');

            time[3] = (typeof str[1] != 'undefined') ? str[1] : 0;

            for(i in time)
                time[i] = parseFloat(time[i]);

            time = new Date(time[0], time[1] - 1, time[2], time[3]);

            if((time.getTime() - Date.now()) / (1000*60*60*24) > 1)
                tmp = _t.template['day'].replace('{{DAY}}', day);

            $(this).countdown({
                until: time, 
                layout: tmp,
                timeSeparator: '<span class="sep">:</span>',
                onExpiry: function(){
                    console.log('TIME IS OVER!');
                }
            });
        });
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['main'])[0])){
            _t.observer = new IntersectionObserver(function(entries, observer){
                for(i in entries){
                    if(entries[i].intersectionRatio > 0 && ! _t.loaded){
                        _t.loaded = true;
                        $.cachedScript(extJSPath + 'jquery.countdown.min.js').done(function(script, textStatus) {
                            _t.build();
                        });
                    }
                }
            });
            _t.observer.observe($(_t.el['main'])[0]);
        }
    }
};