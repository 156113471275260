var wt = parseFloat(window.innerWidth),
ht = parseFloat(window.innerHeight),
wst = document.body.scrollTop || document.documentElement.scrollTop || 0,
sRatio = wst / (document.body.clientHeight - ht),
events = {
    loaded: function () {
        stage.dispatchEvent("CustomEvent", "EVENTS_ON_LOAD");
    },

    contentLoaded: function () {
        stage.dispatchEvent("CustomEvent", "EVENTS_ON_CONTENT_LOADEDE");
    },

    ready: function () {
        stage.dispatchEvent("CustomEvent", "EVENTS_ON_READY");
    },

    onResize: function () {
        wt = parseFloat(window.innerWidth);
        ht = parseFloat(window.innerHeight);

        stage.dispatchEvent("CustomEvent", "EVENTS_ON_RESIZE");
    },

    onScroll: function () {
        wst = document.body.scrollTop || document.documentElement.scrollTop || 0;
        sRatio = wst / (document.body.clientHeight - ht);

        stage.dispatchEvent("CustomEvent", "EVENTS_ON_SCROLL");
    },

    init: function () {
        var _t = this;

        window.addEventListener('load', function(event){
            _t.loaded()
        });

        document.addEventListener('DOMContentLoaded', function(event){
            _t.contentLoaded();
        });

        document.addEventListener('readystatechange', function(event){
            if (document.readyState === 'complete')
                _t.ready();
        });

        window.addEventListener('scroll', _t.onScroll);

        window.addEventListener('resize', _t.onResize);

    }
};