var customSearch = {
    el: {
        header: '#header-search',
        input: '#searchText',
        clearBtn: '.btn-clear',
        btn: '.header-top .btn-search'
    },
    cls: {
        searching: 'searching',
        searched: 'searched',
        nores: 'no-result'
    },
    addEvent: function () {
        var _t = this,
            header = $(_t.el.header),
            input = header.find(_t.el.input),
            clearBtn = header.find(_t.el.clearBtn);

        // input
        //     .on('keyup', function () {
        //         var ths = $(this),
        //             val = (ths.val() || '').trim();

        //         if (val.length > 0)
        //             header.addClass(_t.cls['searching']);
        //         else
        //             header.removeClass(_t.cls['searching']);
        //     });
        $(_t.el['btn']).off('click').on('click', function(){
            $(_t.el['input']).trigger('focus');
        });

        clearBtn
            .on('click', function (evt) {
                evt.preventDefault();
                input.val('').trigger('focus');
                header.removeClass(_t.cls['searching'] + ' ' + _t.cls['searched'] + ' ' + _t.cls['no-result']);
            });
    },
    init: function () {
        var _t = this;
        _t.addEvent();
    }
};

// Support Search
var supportSearch = {
    el: {
        main: '.spt-search',
        kws: '.spt-search-kws',
        search: '.spt-search-input .btn-search',
        back: '.spt-search-input .btn-back',
        input: '.spt-search-input input'
    },
    smartSuggestion: function(){
        var _t = this, curr = 0, len = $(_t.el['main'] + ' ' + _t.el['kws'] + ' span').length - 1, time = parseFloat($(_t.el['kws']).attr('data-interval') || 5000);

        setInterval(function(){
            curr = $(_t.el['main'] + ' ' + _t.el['kws'] + ' span.active').index() + 1;

            if(curr > len)
                curr = 0;

            $(_t.el['main'] + ' ' + _t.el['kws'] + ' span.active').removeClass('active');
            $(_t.el['main'] + ' ' + _t.el['kws'] + ' span:eq(' + curr + ')').addClass('active');
        }, time);
    },
    close: function(){
        var _t = this;

        bdy.removeClass('spt-search-ready');
        $(_t.el['main']).removeClass('searching');
    },
    bind: function(){
        var _t = this;

        $(_t.el['input']).off('focus').on('focus', function(){
            bdy.addClass('spt-search-ready');
        });

        if( ! isMobile){
            $(_t.el['input']).off('blur').on('blur', function(){
                setTimeout(function(){
                    _t.close();
                }, 300);
            });
        }

        $(_t.el['back'] + ', #overlay').off('click').on('click', function(){
            _t.close();
        });

        $(_t.el['search']).off('click').on('click', function(){
            bdy.addClass('spt-search-ready');
            $(_t.el['input']).trigger('focus');
        });
    },
    init: function(){
        var _t = this;

        if(utils.detectEl($(_t.el['main'])[0])){
            if(utils.detectEl($(_t.el['kws'])[0]))
                _t.smartSuggestion();
            _t.bind();
        }
    }
};