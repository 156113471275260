// obj = {title: '', url: ''};
var shareItem = {
    mobile: function(obj){
        if(navigator.share){
            console.log('Web Share API is Supported');
            navigator.share(obj).then(function(){
                console.log('Shared!', obj);
            }).catch(function(error){
                console.log('Not Shared! ', error, obj);
            });
        }else{
            console.log('Web Share API is not Supported');
        }
    }
};

// Copy to Clipboard
var clipboardItem = {
    el: {
        main: '[data-clipboard]',
        input: '#clipboard',
        copied: '.copied-text'
    },
    template: SITE_CONFIG.general.clipboard,
    opt: {
        input: {},
        str: '',
        itxt: '',
        ctxt: ''
    },
    init: function(ths){
        var _t = this;

        ths = $(ths);
        _t.opt['str'] = ths.attr('data-clipboard'),
        _t.opt['ctxt'] = ths.attr('data-copied-text');
        _t.opt['itxt'] = ths.text().trim();

        if( ! ths.hasClass('copied')){
            ths.after(_t.template.replace('{{VAL}}', _t.opt['str']));

            _t.opt['input'] = $(_t.el['input']);

            _t.opt['input'].trigger('select');
            document.execCommand("copy");
            _t.opt['input'].remove();

            if(_t.opt['itxt'] != ''){
                ths.attr('data-text', _t.opt['itxt']);
                ths.find('span').text(_t.opt['ctxt']);
            }else{
                if($('.copied-text', ths).length == 0)
                    ths.append('<span class="copied-text">' + _t.opt['ctxt'] + '</span>');
            }

            ths.addClass('copied');
            setTimeout(function(){
                ths.removeClass('copied');

                if(ths[0].hasAttribute('data-text')){
                    ths.find('span').text(ths.attr('data-text'));
                    ths.removeAttr('data-text');
                }
            }, 3000);
        }
    }
};

var customShare = {
    obj: {
        title: '',
        url: ''
    },
    types:{
        plp: {main: '.prd-inner', title: '.prd-name', url: '.prd-detail a'},
        pdp: {main: '.pdp', title: '.pdp-name h1', url: 'current'},
        mll: {main: '.evt-item', title: '.evt-title', url: '.evt-link'},
        mld: {main: '.cnt-inner', title: '.cnt-title', url: 'current'}
    },
    getObj: function(ths, type){
        var _t = this, prn = $(ths).parents(_t.types[type]['main']);

        _t.obj['title'] = prn.find(_t.types[type]['title']).text();

        _t.getUrl(ths, type);
    },
    getUrl: function(ths, type){
        var _t = this, prn = $(ths).parents(_t.types[type]['main']);

        _t.obj['url'] = (_t.types[type]['url'] != 'current') ? prn.find(_t.types[type]['url']).attr('href') : SITE_CONFIG.general.url;
    },
    prepareLinks: function(){
        var _t = this, url = encodeURIComponent(_t.obj['url']);

        $('.menu-btn-share-fb').attr('href', SITE_CONFIG.general.share.fb.replace('{{URL}}', url));
        $('.menu-btn-share-tw').attr('href', SITE_CONFIG.general.share.tw.replace('{{URL}}', url));
    },
    init: function(){
        var _t = this;

        $('.menu-btn-share').off('click').on('click', function(){
            shareItem.mobile(_t.obj);
        });

        $('.menu-btn-copy').off('click').on('click', function(){
            $(this).attr('data-clipboard', _t.obj['url']);
            clipboardItem.init(this);
        });
    }
};