var scrollEffector = {
    elm: '',
    cls: 'animating',
    anim: '',
    options: {
        trigger: {
            start: '0 100%',
            scrub: true
        }
    },
    build: function(){
        var _t = this, sets = {}, st, elm = document.querySelectorAll(_t.elm);
        
        if(elm.length > 0){
            gsap.registerPlugin(ScrollTrigger);

            elm.forEach(function(ths){
                sets = {trigger: {}, tween: {}}, st = _t.anim[ths.getAttribute('data-se-anim')];
                
                if(st != undefined){
                    // Trigger Options
                    for(i in _t.options['trigger'])
                    sets['trigger'][i] = _t.options['trigger'][i];
    
                    sets['trigger']['trigger'] = ths;
                    sets['trigger']['toggleClass'] = _t.cls;
    
                    if(typeof st['trigger'] != 'undefined'){
                        for(i in st['trigger'])
                            sets['trigger'][i] = st['trigger'][i];
                    }

                    // Tween Options
                    if(typeof st['tween'] != 'undefined')
                        sets['tween'] = st['tween'];

                    sets['tween']['scrollTrigger'] = sets['trigger'];
                    
                    gsap.to(ths, sets['tween']);
                }
            });
        }
    },
    init: function(elm, anim){
        var _t = this;

        _t.elm = elm;
        _t.anim = anim;
  
        _t.build();
    }
};